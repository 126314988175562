import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dayList } from '../../../constants/dayList';
import { crudTypes } from '../../../constants/crudTypes';
import { drawersActions } from '../../../redux/slices/drawers.slice';
import { selectUser } from '../../../redux/slices/user.slice.js';
import {
  addStaff,
  editStaffById,
  getStaffById,
} from '../../../Api/tenant/staff';
import { getServiceList } from '../../../Api/tenant/services';
import { getLocationList } from '../../../Api/tenant/locations';

const requiredFields = ['firstName', 'lastName', 'email'];

const useStaffDrawer = ({ crudType, data = null, refetchData }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(selectUser);
  // staff related
  const [staffData, setStaffData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    active: true,
    type: 'staff',
    profession: '',
    note: '',
  });
  const [specialDays, setSpecialDays] = useState([]);
  const [holidays, setHolidays] = useState([]);

  //  user related
  const [image, setImage] = useState(null);

  const [spts, setSpts] = useState(false);
  // timesheet related
  const [timesheets, setTimesheets] = useState(dayList);

  // linked locations - location tab
  const [locations, setLocations] = useState([]);

  // linked services - service tab
  const [services, setServices] = useState([]);

  // error handling
  const [errors, setErrors] = useState({});

  const toggleDrawer = () => {
    dispatch(drawersActions.closeDrawer());
  };

  // API
  const fetchStaffData = useCallback(async () => {
    try {
      const response = await getStaffById(data.id);
      if (response.status === 200) {
        const result = response.data;
        console.log(result);
        setTimesheets(result.timesheets);
        setHolidays(result?.holidays || []);
        setSpecialDays(result.specialDays);
        setSpts(result.spts);
        setStaffData({ ...staffData, ...result, ...result.User });
        setServices(result.services);
        setLocations(result.locations);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (crudType === crudTypes.EDIT && data?.id) {
      fetchStaffData();
    }

    // preselect locations and services
    if (crudType === crudTypes.ADD) {
      getServiceList(1000, 1).then((res) => {
        if (res.data?.rows) {
          setServices(res.data?.rows.map((ser) => ({ id: ser.id })));
        }
      });
      getLocationList(1000, 1).then((res) => {
        if (res.data?.rows) {
          setLocations(res.data?.rows.map((loc) => ({ id: loc.id })));
        }
      });
    }
  }, [crudType, fetchStaffData]);

  const handleAddStaff = async () => {
    // error handling
    let err = {};
    let data = { ...staffData };

    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });

    // request logic
    const formdata = new FormData();

    for (const key in staffData) {
      if (key !== 'timesheets' && key !== 'services' && key !== 'locations') {
        formdata.append(key, staffData[key]);
      }
    }

    formdata.append('image', image);

    locations.map((location) => {
      formdata.append('locations', JSON.stringify({ id: location.id }));
    });

    services.map((service) => {
      formdata.append('services', JSON.stringify({ id: service.id }));
    });

    timesheets.map((timesheet) => {
      formdata.append('timesheets', JSON.stringify(timesheet));
    });

    specialDays.map((specialDay) => {
      formdata.append('specialDays', JSON.stringify(specialDay));
    });

    if (Object.keys(err).length === 0) {
      try {
        const response = await addStaff(formdata);

        if (response.status >= 200 && response.status < 300) {
          toggleDrawer();
          refetchData();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleEditStaff = async () => {
    const newTimesheets = timesheets.map((timesheet) => {
      timesheet.breaks = timesheet.breaks.map((breakTime) => {
        if (breakTime?.id?.toString().includes('breaks')) delete breakTime.id;
        return breakTime;
      });
      return timesheet;
    });

    const newSpecialDays = specialDays.map((specialDay) => {
      if (specialDay?.id?.toString().includes('special-day'))
        delete specialDay.id;

      specialDay.breaks = specialDay.breaks.map((breakTime) => {
        if (breakTime?.id?.toString().includes('breaks')) delete breakTime.id;
        return breakTime;
      });

      return specialDay;
    });

    const formdata = new FormData();

    for (const key in staffData) {
      if (key !== 'timesheets' && key !== 'services' && key !== 'locations') {
        formdata.append(key, staffData[key]);
      }
    }

    locations.map((location) => {
      formdata.append('locations', JSON.stringify({ id: location.id }));
    });

    services.map((service) => {
      formdata.append('services', JSON.stringify({ id: service.id }));
    });

    newTimesheets.map((timesheet) => {
      formdata.append('timesheets', JSON.stringify(timesheet));
    });

    newSpecialDays.map((specialDay) => {
      formdata.append('specialDays', JSON.stringify(specialDay));
    });

    formdata.append('image', image);
    formdata.append('userId', user.id);

    try {
      const response = await editStaffById(formdata, data.id);
      if (response.status >= 200 && response.status < 300) {
        toggleDrawer();
        if (refetchData) refetchData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = () => {
    switch (crudType) {
      case crudTypes.ADD:
        handleAddStaff();
        break;
      case crudTypes.EDIT:
        handleEditStaff();
        break;
      default:
        break;
    }
  };

  return {
    staffData,
    image,
    holidays,
    specialDays,
    timesheets,
    spts,
    errors,
    locations,
    services,
    setServices,
    setLocations,
    setErrors,
    setSpts,
    setTimesheets,
    setSpecialDays,
    setHolidays,
    setImage,
    setStaffData,
    toggleDrawer,
    handleSubmit,
  };
};

export default useStaffDrawer;
