import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../../components/CheckoutForm';
import { getPaymentIntent } from '../../Api/tenant/stripe';
import { selectUser } from '../../redux/slices/user.slice.js';
import { useSelector } from 'react-redux';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  'pk_test_51NQFcTHtKKzOm3031vhVvqmqUwQgg20EXND4UgBLK8XO0Z4vqcBt23MHXoTp8VLlCOkXI43dkhudcXsoSD8oajEm00Xy40y2Mc'
);

export default function PaymentStep({ selectedModules }) {
  const [clientSecret, setClientSecret] = useState('');
  const [error, setError] = useState('');

  const { user, isUserLoading } = useSelector(selectUser);
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    const selectedModulesIds = selectedModules.map((mod) => mod.id);
    getPaymentIntent(user.TenantId, {
      modules: selectedModulesIds,
    }).then(
      (res) => {
        console.log(res.data);
        setClientSecret(res.data.clientSecret);
      },
      ({ response }) => {
        console.log(response);
        setError(response.data);
      }
    );
  }, [isUserLoading]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {error && error}
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
}
