import React from 'react';
import iconPending from '../../assets/images/icon_pending_dashboard.png';
import iconOngoing from '../../assets/images/icon_ongoing_dashboard.png';
import iconCompleted from '../../assets/images/icon_completed_dashboard.png';
import iconCancelled from '../../assets/images/icon_cancelled_dashboard.png';
import PriceFormatter from '../../utils/PriceFormatter';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { durationFormater } from '../../utils/durationFormater';
import { useTranslation } from 'react-i18next';

function StatusDetailsBar({ filterData }) {
  const { t } = useTranslation(['dashboard', 'common']);

  return (
    <Grid
      spacing={'20px'}
      container
      sx={{
        mt: '0px',
        display: 'flex',
        px: '20px',
      }}
    >
      <Grid item xs={12} sm={6} lg={3}>
        <Card
          sx={{
            boxShadow: 'rgba(58, 58, 80, 0.1) 0px 0px 15px',
            flex: 1,
            borderRadius: '0px',
          }}
        >
          <CardContent
            sx={{
              // display: 'flex',
              // justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
              }}
            >
              <Box
                sx={{
                  width: '80px',
                  height: '80px',
                  position: 'relative',
                }}
              >
                <img
                  src={iconPending}
                  alt=""
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    color: '#3A3A50',
                  }}
                >
                  {filterData?.appointmentsData?.pending.count || '0'}{' '}
                  {t('common:statuses.pending')}
                </Typography>
                <Typography
                  sx={{
                    color: '#3A3A50',
                  }}
                >
                  {filterData?.appointmentsData?.pending.count || '0'}{' '}
                  {t('dashboard:dataWidget.calculated')}:{' '}
                  {PriceFormatter.format(
                    filterData?.appointmentsData?.pending.sum || '0'
                  )}
                </Typography>
                <Typography
                  sx={{
                    color: '#3A3A50',
                  }}
                >
                  {t('dashboard:dataWidget.duration')}:{' '}
                  {durationFormater(
                    filterData?.appointmentsData?.pending.duration
                  ) || '0'}
                </Typography>
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Card
          sx={{
            borderRadius: '0px',
            flex: 1,
            boxShadow: 'rgba(58, 58, 80, 0.1) 0px 0px 15px',
          }}
        >
          <CardContent
            sx={{
              // justifyContent: 'space-between',
              // display: 'flex',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
              }}
            >
              <Box
                sx={{
                  width: '80px',
                  height: '80px',
                  position: 'relative',
                }}
              >
                <img
                  src={iconOngoing}
                  alt=""
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    color: '#3A3A50',
                  }}
                >
                  {filterData?.appointmentsData?.ongoing.count || '0'}{' '}
                  {t('common:statuses.ongoing')}
                </Typography>
                <Typography
                  sx={{
                    color: '#3A3A50',
                  }}
                >
                  {t('dashboard:dataWidget.calculated')}:{' '}
                  {PriceFormatter.format(
                    filterData?.appointmentsData?.ongoing.sum || '0'
                  )}
                </Typography>
                <Typography
                  sx={{
                    color: '#3A3A50',
                  }}
                >
                  {t('dashboard:dataWidget.duration')}:{' '}
                  {durationFormater(
                    filterData?.appointmentsData?.ongoing.duration
                  ) || '0'}
                </Typography>
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Card
          sx={{
            flex: 1,
            borderRadius: '0px',
            boxShadow: 'rgba(58, 58, 80, 0.1) 0px 0px 15px',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
              }}
            >
              <Box
                sx={{
                  width: '80px',
                  height: '80px',
                  position: 'relative',
                }}
              >
                <img
                  src={iconCompleted}
                  alt=""
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography sx={{ color: '#3A3A50' }}>
                  {filterData?.appointmentsData?.completed.count || '0'}{' '}
                  {t('common:statuses.completed')}
                </Typography>
                <Typography sx={{ color: '#3A3A50' }}>
                  {t('dashboard:dataWidget.calculated')}:{' '}
                  {PriceFormatter.format(
                    filterData?.appointmentsData?.completed.sum || '0'
                  )}
                </Typography>
                <Typography sx={{ color: '#3A3A50' }}>
                  {t('dashboard:dataWidget.duration')}:{' '}
                  {durationFormater(
                    filterData?.appointmentsData?.completed.duration
                  ) || '0'}
                </Typography>
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Card
          sx={{
            boxShadow: 'rgba(58, 58, 80, 0.1) 0px 0px 15px',
            flex: 1,
            borderRadius: '0px',
          }}
        >
          <CardContent
            sx={{
              // display: 'flex',
              // justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
              }}
            >
              <Box
                sx={{
                  width: '80px',
                  height: '80px',
                  position: 'relative',
                }}
              >
                <img
                  src={iconCancelled}
                  alt=""
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography sx={{ color: '#3A3A50' }}>
                  {filterData?.appointmentsData?.cancelled.count || '0'}{' '}
                  {t('common:statuses.cancelled')}
                </Typography>
                <Typography sx={{ color: '#3A3A50' }}>
                  {t('dashboard:dataWidget.calculated')}:{' '}
                  {PriceFormatter.format(
                    filterData?.appointmentsData?.cancelled.sum || '0'
                  )}
                </Typography>
                <Typography sx={{ color: '#3A3A50' }}>
                  {t('dashboard:dataWidget.duration')}:{' '}
                  {durationFormater(
                    filterData?.appointmentsData?.cancelled.duration
                  ) || '0'}
                </Typography>
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default StatusDetailsBar;
