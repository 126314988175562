import React from 'react';
import Drawer from '../../../components/Drawer';
import DrawerFooter from '../../../components/DrawerFooter';
import Grid from '@mui/material/Grid';
import StaffTab from './Tabs';
import { useMediaQuery, useTheme } from '@mui/material';
import CustomTextButton from '../../../components/CustomButtons/CustomTextButton';
import useStaffDrawer from './useStaffDrawer';
import { crudTypes } from '../../../constants/crudTypes';
import { useTranslation } from 'react-i18next';

const StaffDrawer = ({
  title,
  crudType,
  submitTitle,
  data = null,
  refetchData = null,
}) => {
  const { t } = useTranslation('common');
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  const {
    staffData,
    image,
    holidays,
    specialDays,
    timesheets,
    spts,
    errors,
    locations,
    services,
    setServices,
    setLocations,
    setErrors,
    setSpts,
    setTimesheets,
    setSpecialDays,
    setHolidays,
    setImage,
    setStaffData,
    toggleDrawer,
    handleSubmit,
  } = useStaffDrawer({ crudType, data, refetchData });

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer type={crudType} title={title} toggleDrawer={toggleDrawer}>
          {/* -----------FORM-------- */}
          <Grid sx={{ height: '100%', overflowY: 'auto', width: '100%' }}>
            {(crudType === crudTypes.ADD || crudType === crudTypes.EDIT) && (
              <StaffTab
                // TODO:
                // !------------------TODO: MAKE THEM ONE STATE---------------------
                // * Already did the staff details
                staffData={staffData}
                setStaffData={setStaffData}
                userData={staffData}
                setUserData={setStaffData}
                drawerData={staffData}
                // !---------------------------------------------------------------
                image={image}
                setImage={setImage}
                holidays={holidays}
                setHolidays={setHolidays}
                specialDays={specialDays}
                setSpecialDays={setSpecialDays}
                timesheets={timesheets}
                setTimesheets={setTimesheets}
                spts={spts}
                setSpts={setSpts}
                drawerType={crudType}
                errors={errors}
                setErrors={setErrors}
                locations={locations}
                setLocations={setLocations}
                services={services}
                setServices={setServices}
              />
            )}
          </Grid>

          {/* -----Footer--------*/}
          <DrawerFooter>
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: isSmallScreen ? 'flex-start' : 'flex-end',
                }}
              >
                {crudType === crudTypes.EDIT && ''}
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                  width: isSmallScreen ? '100%' : 'auto',
                  alignItems: 'center',
                }}
              >
                <CustomTextButton
                  variant="contained"
                  size="large"
                  onClick={() => {
                    toggleDrawer();
                  }}
                >
                  {t('common:buttons.cancel')}
                </CustomTextButton>
                <CustomTextButton
                  type="blue"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                >
                  {submitTitle || t('common:buttons.save')}
                </CustomTextButton>
              </div>
            </>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default StaffDrawer;
