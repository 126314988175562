import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useEffect } from 'react';
import { useTimesheet } from '../../hooks/useTimesheet';
import { timesList } from '../../constants/timesList';
import dayjs from 'dayjs';
import { IOSSwitch } from '../CustomCheckbox';
import { useTranslation } from 'react-i18next';

const WeeklySchedule = ({ timesheets, setTimesheets, spts, setSpts }) => {
  const { t } = useTranslation('location');

  const {
    handleChange,
    handleDayOff,
    addBreakToDayData,
    handleUpdateBreakTimes,
    deleteBreak,
  } = useTimesheet({ timesheets, setTimesheets });

  const handelSwitch = () => {
    setSpts((prev) => !prev);
  };

  console.log(timesheets);

  return (
    <Grid xs={12} sm={12} sx={{ py: { sm: 2 } }}>
      <Grid
        container
        alignItems="center"
        item
        xs={12}
        sx={{
          cursor: 'pointer',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          borderRadius: '4px',

          height: 45,
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: '.7rem',
          msTransition: 3,
        }}
        onClick={handelSwitch}
      >
        <Typography variant="subtitle2">
          {t('location:drawer.businessHours.configureSpecificTimesheet')}
        </Typography>
        <FormControlLabel control={<Switch checked={spts} />} />
      </Grid>
      {spts &&
        timesheets.map((data, index) => (
          <Grid container xs={12} sm={12} key={data.id} item>
            <Typography
              variant="subtitle1"
              color="#585cba"
              fontSize="14px"
              fontWeight="bold"
              marginBottom="10px"
              marginTop="1rem"
            >
              {index + 1}. {data.day}
            </Typography>
            <Grid xs={12} sm={12} container>
              <Grid
                xs={12}
                sm={3.8}
                sx={{ marginTop: { xs: '10px', sm: '0' } }}
                backgroundColor={data.dayOff ? '#e3eaf3' : ''}
                item
              >
                <Autocomplete
                  disabled={data.dayOff}
                  size="small"
                  disablePortal
                  disableClearable
                  options={timesList.map((option) => option.label)}
                  value={dayjs(data?.from, 'mm:ss').format('mm:ss') || null}
                  sx={{
                    width: '100%',
                    fontSize: '14px',
                  }}
                  onChange={(e, value) => {
                    handleChange(value, data.id, 'from');
                  }}
                  renderInput={(parPMs) => (
                    <TextField
                      {...parPMs}
                      InputProps={{
                        ...parPMs.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccessAlarmsIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Select..."
                    />
                  )}
                />
              </Grid>
              <Grid
                xs={12}
                sm={3.8}
                backgroundColor={data.dayOff ? '#e3eaf3' : ''}
                item
                sx={{
                  marginRight: { xs: 0, sm: '16px' },
                  marginTop: { xs: '10px', sm: '0' },
                }}
              >
                <Autocomplete
                  size="small"
                  disabled={data.dayOff}
                  disableClearable
                  options={timesList.map((option) => option.label)}
                  value={dayjs(data?.to, 'mm:ss').format('mm:ss') || null}
                  sx={{
                    width: '100%',
                    fontSize: '14px',
                  }}
                  getOptionDisabled={(option) =>
                    dayjs(option, 'mm:ss') < dayjs(data.from, 'mm:ss')
                  }
                  onChange={(e, value) => {
                    handleChange(value, data.id, 'to');
                  }}
                  renderInput={(parPMs) => (
                    <TextField
                      {...parPMs}
                      InputProps={{
                        ...parPMs.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccessAlarmsIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Select..."
                    />
                  )}
                />
              </Grid>
              <Grid
                id="once-per-person"
                container
                alignItems="center"
                item
                xs={12}
                sm={4}
                sx={{
                  cursor: 'pointer',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: '4px',
                  height: 40,
                  marginTop: { xs: '10px', sm: '0' },
                }}
                onClick={() => handleDayOff(data.id)}
              >
                <IOSSwitch
                  size="small"
                  checked={data.dayOff}
                  sx={{ color: '#8b97a1' }}
                />
                <Typography
                  variant="subtitle2"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#8b97a1',
                  }}
                >
                  Add day off
                </Typography>
              </Grid>
            </Grid>
            {!data.dayOff && (
              <>
                {data.breaks.length !== 0 && (
                  <Typography
                    variant="subtitle1"
                    color="#fd9b78"
                    fontSize="14px"
                    fontWeight="bold"
                    sx={{ my: 3 }}
                  >
                    Breaks
                  </Typography>
                )}
                <Grid xs={12} sm={12} container>
                  {data.breaks.length !== 0 &&
                    data.breaks.map((breakTime) => (
                      <Grid key={breakTime.id} container marginBottom={1}>
                        <Grid xs={5.5} item>
                          <Autocomplete
                            options={timesList.map((option) => option.label)}
                            size="small"
                            disablePortal
                            disableClearable
                            value={
                              dayjs(breakTime?.from, 'mm:ss').format('mm:ss') ||
                              null
                            }
                            sx={{
                              width: '100%',
                              fontSize: '14px',
                            }}
                            getOptionDisabled={(option) =>
                              dayjs(option, 'mm:ss') <
                                dayjs(data.from, 'mm:ss') ||
                              dayjs(option, 'mm:ss') > dayjs(data.to, 'mm:ss')
                            }
                            onChange={handleUpdateBreakTimes(
                              'from',
                              breakTime.id,
                              data.id
                            )}
                            renderInput={(parPMs) => (
                              <TextField
                                {...parPMs}
                                InputProps={{
                                  ...parPMs.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <AccessAlarmsIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                placeholder="Select..."
                              />
                            )}
                          />
                        </Grid>
                        <Grid xs={5.5} item>
                          <Autocomplete
                            options={timesList.map((option) => option.label)}
                            size="small"
                            disablePortal
                            disableClearable
                            sx={{
                              width: '100%',
                              fontSize: '14px',
                            }}
                            value={
                              dayjs(breakTime?.to, 'mm:ss').format('mm:ss') ||
                              null
                            }
                            getOptionDisabled={(option) =>
                              dayjs(option, 'mm:ss') <
                                dayjs(data.from, 'mm:ss') ||
                              dayjs(option, 'mm:ss') <
                                dayjs(breakTime.from, 'mm:ss') ||
                              dayjs(option, 'mm:ss') > dayjs(data.to, 'mm:ss')
                            }
                            onChange={handleUpdateBreakTimes(
                              'to',
                              breakTime.id,
                              data.id
                            )}
                            renderInput={(parPMs) => (
                              <TextField
                                {...parPMs}
                                InputProps={{
                                  ...parPMs.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <AccessAlarmsIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                placeholder="Select..."
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          id="once-per-person"
                          container
                          alignItems="center"
                          item
                          xs={1}
                          sm={1}
                          sx={{
                            cursor: 'pointer',
                            height: 40,
                            display: 'flex',
                            justifyContent: 'center',
                            color: '#fd9b78',
                          }}
                        >
                          <DeleteIcon
                            onClick={() => deleteBreak(data.id, breakTime.id)}
                          />
                        </Grid>
                      </Grid>
                    ))}

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="left"
                    sx={{ cursor: 'pointer', my: 3 }}
                    onClick={() => addBreakToDayData(data.id)}
                  >
                    <AddCircleIcon sx={{ color: '#828f9a' }} />
                    <Typography
                      variant="subtitle2"
                      color="#828f9a"
                      marginLeft="5px"
                    >
                      Add Break
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        ))}
    </Grid>
  );
};

export default WeeklySchedule;
