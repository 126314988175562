import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useState } from 'react';
import CompanyLocation from './CompanyLocation';
import LocationStory from './LocationStory';
import NetworkImages from './NetworkImages';
import WeeklySchedule from '../../../components/WeeklySchedule/WeeklySchedule';
import LocationSettings from './LocationSettings';
import { LocationServices } from './LocationService';
import { LocationStaff } from './LocationStaff';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            padding: 3,
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTab = styled(Tab)({
  fontSize: '12px',
  fontWeight: 'bold',
  padding: 0,
  marginRight: '14px',
});

export default function LocationTab({
  companyLocation,
  setCompanyLocation,
  timesheets,
  setTimesheets,
  spts,
  setSpts,
  interiorList,
  setInteriorList,
  exteriorList,
  setExteriorList,
  interiorCurrentList,
  setInteriorCurrentList,
  exteriorCurrentList,
  setExteriorCurrentList,
  image,
  setImage,
  locationSettings,
  setLocationSettings,
  locationStory,
  setLocationStory,
  logo,
  setLogo,
  drawerType,
  errors,
  setErrors,
  exteriorImages,
  interiorImages,
  setUploadedInteriorCurrentList,
  setUploadedExteriorCurrentList,
  uploadedInteriorCurrentList,
  uploadedExteriorCurrentList,
  services,
  setServices,
  staff,
  setStaff,
}) {
  const { t } = useTranslation('location');

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 3, mt: 2 }}>
        <Box>
          <Tabs
            sx={{ fontSize: '10px' }}
            variant="scrollable"
            value={value}
            onChange={handleChange}
          >
            <StyledTab
              label={t('location:drawer.companyLocation.title')}
              {...a11yProps(0)}
            />
            <StyledTab
              label={t('location:drawer.services.title')}
              {...a11yProps(1)}
            />
            <StyledTab
              label={t('location:drawer.staff.title')}
              {...a11yProps(2)}
            />
            <StyledTab
              label={t('location:drawer.locationStory.title')}
              {...a11yProps(3)}
            />
            <StyledTab
              label={t('location:drawer.networkImages.title')}
              {...a11yProps(4)}
            />
            <StyledTab
              label={t('location:drawer.businessHours.title')}
              {...a11yProps(5)}
            />
            <StyledTab
              label={t('location:drawer.networkSettings.title')}
              {...a11yProps(6)}
            />
          </Tabs>
        </Box>
      </Box>
      <TabPanel value={value} index={0}>
        <CompanyLocation
          formData={companyLocation}
          setFormData={setCompanyLocation}
          logo={logo}
          setLogo={setLogo}
          errors={errors}
          setErrors={setErrors}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LocationServices services={services} setServices={setServices} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <LocationStaff staff={staff} setStaff={setStaff} />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <LocationStory
          locationStory={locationStory}
          setLocationStory={setLocationStory}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <NetworkImages
          interiorList={interiorList}
          setInteriorList={setInteriorList}
          exteriorList={exteriorList}
          setExteriorList={setExteriorList}
          interiorCurrentList={interiorCurrentList}
          setInteriorCurrentList={setInteriorCurrentList}
          exteriorCurrentList={exteriorCurrentList}
          setExteriorCurrentList={setExteriorCurrentList}
          image={image}
          setImage={setImage}
          drawerType={drawerType}
          {...{
            interiorImages,
            exteriorImages,
            setUploadedInteriorCurrentList,
            setUploadedExteriorCurrentList,
            uploadedInteriorCurrentList,
            uploadedExteriorCurrentList,
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <WeeklySchedule
          timesheets={timesheets}
          setTimesheets={setTimesheets}
          spts={spts}
          setSpts={setSpts}
        />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <LocationSettings
          locationSettings={locationSettings}
          setLocationSettings={setLocationSettings}
        />
      </TabPanel>
    </Box>
  );
}
