import { Avatar, Box, Divider, Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import StatusChip from '../../../../components/StatusChip';
import PriceFormatter from '../../../../utils/PriceFormatter';
import classes from './Details.module.css';
import CommentSection from './CommentSection';
import { useTranslation } from 'react-i18next';

function Details({ drawerData }) {
  const { t } = useTranslation('common');

  const tempInfos = useMemo(
    () => [
      {
        title: t('common:tableHeaders.locations'),
        value: `${drawerData?.location?.address || '-'}, ${
          drawerData?.location?.name || '-'
        }`,
      },
      {
        title: t('common:tableHeaders.date'),
        value: `${drawerData?.date || '-'}, ${drawerData?.time || '-'}`,
      },
      {
        title: t('common:tableHeaders.service'),
        value: `${drawerData?.service?.name || '-'}`,
      },
      { title: t('common:tableHeaders.serviceExtra'), value: '-' },
      {
        title: t('common:tableHeaders.price'),
        value: `${drawerData?.service?.price || '-'}`,
      },
      {
        title: t('common:tableHeaders.duration'),
        value: `${drawerData?.service?.duration || '-'}h`,
      },
      {
        title: t('common:tableHeaders.note'),
        value: `${drawerData?.note || '-'}`,
      },
    ],
    [drawerData]
  );

  return (
    <Box>
      <Grid xs={12} className={classes.info__container}>
        {tempInfos.map((info) => (
          <Grid xs={12} md={6} className={classes.info__row}>
            <Typography className={classes.info__title}>
              {info.title}
            </Typography>
            <Typography className={classes.info__value}>
              {info.title === 'Price'
                ? PriceFormatter.format(info.value)
                : info.value}
            </Typography>
          </Grid>
        ))}
        <Grid xs={12} md={6} className={classes.customer__card}>
          <Typography>Booked by sub-customer</Typography>
          <Typography>
            <span style={{ fontWeight: 'bold' }}>
              {t('common:tableHeaders.name')} :
            </span>{' '}
            {`${drawerData?.customer?.User?.firstName || '-'} ${
              drawerData?.customer?.User?.lastName || '-'
            }`}
          </Typography>
          <Typography>
            <span style={{ fontWeight: 'bold' }}>
              {t('common:tableHeaders.phone')}:
            </span>{' '}
            {drawerData?.customer?.User?.phone || '-'}
          </Typography>
          <Typography>
            <span style={{ fontWeight: 'bold' }}>
              {t('common:tableHeaders.email')}:
            </span>{' '}
            {drawerData?.customer?.User?.email || '-'}
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid
        xs={12}
        sx={{ marginTop: '30px' }}
        className={classes.info__container}
      >
        <Grid xs={12} md={6} className={classes.info__row}>
          <Typography color={'#EA9B7F'} className={classes.info__title}>
            {t('common:tableHeaders.companyName')}
          </Typography>
          <Typography className={classes.info__title}>
            Dynamite carwash
          </Typography>
          <Typography className={classes.info__value}>
            contact@dynamite
          </Typography>
          <Typography className={classes.info__value}>
            23525 21334 23423
          </Typography>
        </Grid>
        <Grid xs={12} md={6} className={classes.info__row}>
          <Typography color={'#8467E9'} className={classes.info__title}>
            {t('common:tableHeaders.staff')}
          </Typography>
          <Box display={'flex'}>
            <Avatar>
              {drawerData?.staff?.User?.firstName[0]?.toUpperCase() || '-'}
              {drawerData?.staff?.User?.lastName[0]?.toUpperCase() || '-'}
            </Avatar>
            <Box marginLeft={1}>
              <Typography className={classes.info__title}>
                {drawerData?.staff?.User?.firstName || '-'}{' '}
                {drawerData?.staff?.User?.lastName || '-'}
              </Typography>
              <Typography className={classes.info__value}>
                {drawerData?.staff?.User?.email || '-'}
              </Typography>
              <Typography className={classes.info__value}>
                {drawerData?.staff?.User?.phone || '-'}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid className={classes.status__container}>
          {drawerData.status === 'Custom' ? (
            <Box paddingRight={1}>
              <StatusChip
                color={drawerData?.statusColor}
                bgColor={drawerData?.statusBgColor}
                text={drawerData?.statusText}
                rounded={true}
                width={'max-content'}
              />
            </Box>
          ) : (
            <Box paddingRight={1}>
              <StatusChip
                type={'appointment' + drawerData?.status}
                width={'max-content'}
                text={drawerData?.status}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <CommentSection appointmentId={drawerData.id} />
    </Box>
  );
}

export default Details;
