import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Autocomplete, Stack, TextField } from '@mui/material';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import SelectCustomer from '../../components/AysncAutoComplete/SelectCustomer';
import SelectService from '../../components/AysncAutoComplete/SelectService';
import { addEasyAppointment } from '../../Api/tenant/appointments';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/slices/user.slice.js';
import moment from 'moment';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { timesList } from '../../constants/timesList';
import useInitialGuide from '../../hooks/useInitialGuide';
import { initialGuideAttributes } from '../../constants/initialGuideAttributes';
import { useTranslation } from 'react-i18next';

function EasyBookingWidget({ refetchDashboardData }) {
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedService, setSelectedService] = useState();
  const [date, setDate] = useState(dayjs().add(1, 'day').format('YYYY-MM-DD'));
  const [startTime, setStartTime] = useState('00:00');
  const [endTime, setEndTime] = useState('00:00');
  const { user } = useSelector(selectUser);
  const [resCus, setResCus] = useState(false);
  const { initialGuideData, fetchInitialGuide } = useInitialGuide();
  const { t } = useTranslation('dashboard');

  const handleReset = () => {
    setSelectedCustomer();
    setSelectedService('');
    setDate('');
    setStartTime('');
    setEndTime('');
  };

  const handleBooking = async () => {
    try {
      await addEasyAppointment({
        date,
        time: `${startTime} - ${endTime}`,
        customerId: selectedCustomer,
        serviceId: selectedService,
        status: 'Confirmed',
        userId: user.id,
      });
      handleReset();
      refetchDashboardData();
      if (!initialGuideData[initialGuideAttributes?.bookedAppointment])
        fetchInitialGuide();
    } catch (err) {
      console.log(err);
    }
  };

  const handleStartTime = (e, v) => {
    setStartTime(v);
    if (dayjs(v, 'mm:ss') > dayjs(endTime, 'mm:ss')) setEndTime(v);
  };

  return (
    <Grid
      item
      xs={12}
      sm={6}
      lg={3}
      sx={{
        height: '368px',
        mb: '20px',
      }}
    >
      <Card
        sx={{
          boxShadow: 'rgba(58, 58, 80, 0.1) 0px 0px 15px',
          height: '368px',
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          borderRadius: '0px',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            px: '20px !important',
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#3A3A50',
            }}
          >
            {t('easyBookingWidget.title')}
          </Typography>
          <SelectCustomer
            e2e="select-customer"
            selectedValue={selectedCustomer}
            setSelectedValue={setSelectedCustomer}
            addNewEntity
          />

          <SelectService
            e2e="select-service"
            value={selectedService}
            onChange={(e, v) => {
              setSelectedService(v);
            }}
            addNewEntity
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack>
              <DesktopDatePicker
                minDate={dayjs().add(1, 'day')}
                InputProps={{
                  style: {
                    width: '100%',
                    flexDirection: 'row-reverse',
                  },
                }}
                inputFormat="YYYY-MM-DD"
                value={
                  date
                    ? dayjs(date, 'YYYY-MM-DD')
                    : dayjs().add(1, 'day').format('YYYY-MM-DD')
                }
                onChange={(v) => setDate(v.format('YYYY-MM-DD'))}
                renderInput={(params) => (
                  <TextField size="small" {...params} e2e="select-date" />
                )}
              />
            </Stack>
          </LocalizationProvider>

          <Grid
            sx={{
              display: 'flex',
              gap: '5px',
            }}
          >
            <Autocomplete
              size="small"
              disablePortal
              id="combo-box-demo"
              onChange={handleStartTime}
              value={startTime || null}
              options={timesList.map((option) => option.label)}
              disableClearable
              sx={{
                width: '100%',
                fontSize: '14px',
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="From..." e2e="time-from" />
              )}
            />

            <Autocomplete
              size="small"
              disablePortal
              id="combo-box-demo"
              onChange={(e, v) => setEndTime(v)}
              value={endTime || null}
              options={timesList.map((option) => option.label)}
              disableClearable
              sx={{
                width: '100%',
                fontSize: '14px',
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="To..." e2e="time-to" />
              )}
              getOptionDisabled={(option) =>
                dayjs(option, 'mm:ss') < dayjs(startTime, 'mm:ss')
              }
            />
          </Grid>
          <Grid
            sx={{
              display: 'flex',
              gap: '20px',
            }}
          >
            <CustomTextButton
              e2e="reset-button"
              text={t('easyBookingWidget.resetBtn')}
              onClick={handleReset}
              style={{
                width: '50%',
              }}
            />
            <CustomTextButton
              e2e="book-button"
              text={t('easyBookingWidget.bookBtn')}
              onClick={handleBooking}
              style={{
                width: '50%',
              }}
            />
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default EasyBookingWidget;
