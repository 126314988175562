import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Grow,
  Menu,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import InfoIcon from '@mui/icons-material/Info';
import styled from '@emotion/styled';
import { v4 as uuidv4 } from 'uuid';
import ExtraServiceActionIcon from '../../../../components/ExtraServiceActionIcon';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '../../../../components/Modal';
import ProfileImageField from '../../../../components/ProfileImageField';
import CustomTextField from '../../../../components/CustomTextField';
import { useTranslation } from 'react-i18next';

const initalState = {
  serviceName: '',
  minQuantity: 0,
  maxQuantity: 0,
  price: null,
  hideDuration: false,
  hidePrice: false,
  duration: '',
  isEnabled: true,
};

const duration = [
  {
    duration: '0',
  },
  {
    duration: '15',
  },
  {
    duration: '30',
  },
  {
    duration: '45',
  },
  {
    duration: '60',
  },
  {
    duration: '75',
  },
  {
    duration: '90',
  },
  {
    duration: '105',
  },
  {
    duration: '120',
  },
  {
    duration: '135',
  },
];

const requiredFields = ['serviceName', 'price'];

const Extras = ({ extraService, setExtraService }) => {
  const [activeExtra, setActiveExtra] = useState(null);
  const [isOpenService, setIsOpenService] = useState(true);
  const [extraType, setExtraType] = useState(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedExtraId, setSelectedExtraId] = useState(null);
  const [info, setInfo] = useState(initalState);
  const [userImage, setUserImage] = useState('');
  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState({});

  const { t } = useTranslation(['service', 'common']);

  const handleOpenDeleteModal = (id = null) => {
    setOpenDeleteModal((prevState) => !prevState);
    setSelectedExtraId(id);
  };

  const handleDeleteExtra = () => {
    setExtraService((prevState) => {
      return prevState.filter((extra) => extra.id !== selectedExtraId);
    });
    setOpenDeleteModal((prevState) => !prevState);
    setSelectedExtraId(null);
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };

  const handelSwitch = (name, value) => {
    setInfo({ ...info, [name]: value });
  };

  const handelNewExtra = () => {
    setInfo(initalState);
    setIsOpenService(false);
    setExtraType('add');
  };

  const handleEditExtra = (service) => {
    return () => {
      setInfo(service);
      setIsOpenService(false);
      setExtraType('edit');
    };
  };

  const toggleExtraVisibility = (id) => {
    return () => {
      setExtraService((prevState) => {
        return prevState.map((extra) => {
          if (extra.id !== id) return extra;

          return {
            ...extra,
            isEnabled: !extra.isEnabled,
          };
        });
      });
    };
  };

  const handelSave = () => {
    // validation handling
    let err = {};

    for (const key in info) {
      if (!info[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });

    // handler logic
    if (Object.keys(err).length === 0) {
      if (extraType === 'add') {
        setExtraService([
          ...extraService,
          { ...info, image: userImage, id: uuidv4() },
        ]);
      } else if (extraType === 'edit') {
        setExtraService((prevState) => {
          return prevState.map((extra) => {
            if (extra.id !== info.id) return extra;

            return {
              ...info,
              image: userImage,
            };
          });
        });
      }
      setIsOpenService(true);
    }
  };
  const handelSelect = (name, value) => {
    setInfo({ ...info, [name]: value });
  };
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))`
    & .MuiTooltip-tooltip {
      background: #929da6;
      padding: 0.6rem;
      font-size: 0.8rem;
    }
  `;

  const toggleExtraActions = (id = null) => {
    return () => setActiveExtra(id);
  };

  const visibilityIcon = (isEnabled) =>
    isEnabled ? (
      <VisibilityOffIcon sx={{ color: 'white', fontSize: '15px' }} />
    ) : (
      <VisibilityIcon sx={{ color: 'white', fontSize: '15px' }} />
    );

  const open = Boolean(anchorMenuEl);
  const handleMenuOpen = (event) => {
    setAnchorMenuEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorMenuEl(null);
  };

  return (
    <Grid container>
      <Modal
        handleOpenModal={handleOpenDeleteModal}
        type="DELETE"
        open={openDeleteModal}
        confirmFunc={handleDeleteExtra}
      />

      {isOpenService ? (
        extraService.map((service, index) => (
          <Grid
            xs={12}
            sm={12}
            onMouseEnter={toggleExtraActions(service.id)}
            onMouseLeave={toggleExtraActions()}
            sx={{
              p: 2,
              boxShadow: 'rgb(149 157 165 / 20%) 0px 0px 10px',
              marginBottom: 3,
              position: 'relative',
              opacity: service.isEnabled ? 1 : 0.5,
            }}
            container
            key={index}
          >
            <Grid xs={12} sm={6} sx={{ marginY: 1 }} display="flex">
              <Grid xs={6}>
                <Typography fontWeight="500" color="#6c70dc">
                  {t('service:drawer.extras.serviceName')}:
                </Typography>
              </Grid>
              <Typography color="#828F9A">{service.serviceName}</Typography>
            </Grid>
            <Grid xs={12} sm={6} sx={{ marginY: 1 }} display="flex">
              <Grid xs={6}>
                <Typography fontWeight="500">
                  {t('service:drawer.extras.duration')}:
                </Typography>
              </Grid>
              <Typography color="#828F9A">{service.duration}</Typography>
            </Grid>
            <Grid xs={12} sm={6} sx={{ marginY: 1 }} display="flex">
              <Grid xs={6}>
                <Typography fontWeight="500">
                  {t('service:drawer.extras.price')}:
                </Typography>
              </Grid>
              <Typography color="#828F9A">$ {service.price}</Typography>
            </Grid>
            <Grid xs={12} sm={6} sx={{ marginY: 1 }} display="flex">
              <Grid xs={6}>
                <Typography fontWeight="500">
                  {t('service:drawer.extras.minQuantity')}:
                </Typography>
              </Grid>
              <Typography color="#828F9A">{service.minQuantity}</Typography>
            </Grid>
            <Grid xs={12} sm={6} sx={{ marginY: 1 }} display="flex">
              <Grid xs={6}>
                <Typography fontWeight="500">
                  {t('service:drawer.extras.maxQuantity')}:
                </Typography>
              </Grid>
              <Typography color="#828F9A">{service.maxQuantity}</Typography>
            </Grid>

            {/* Extra Action buttons */}
            <Grow
              in={activeExtra === service.id ? true : false}
              style={{ transformOrigin: '0 0 0' }}
              {...(activeExtra === service.id
                ? { timeout: 1000 }
                : { timeout: 500 })}
            >
              <Grid sx={{ position: 'absolute', right: -10, top: 15 }}>
                <ExtraServiceActionIcon
                  onClick={handleEditExtra(service)}
                  icon={<EditIcon sx={{ color: 'white', fontSize: '15px' }} />}
                  bgColor="#C7CFD9"
                />
                <ExtraServiceActionIcon
                  onClick={toggleExtraVisibility(service.id)}
                  icon={visibilityIcon(service.isEnabled)}
                  bgColor="#C7CFD9"
                />
                <ExtraServiceActionIcon
                  onClick={handleMenuOpen}
                  icon={
                    <ContentCopyIcon
                      sx={{ color: 'white', fontSize: '15px' }}
                    />
                  }
                  bgColor="#C7CFD9"
                />
                <ExtraServiceActionIcon
                  onClick={() => handleOpenDeleteModal(service.id)}
                  icon={<CloseIcon sx={{ color: 'white', fontSize: '15px' }} />}
                  bgColor="#FB3E6E"
                />
                <Menu
                  anchorEl={anchorMenuEl}
                  open={open}
                  onClose={handleMenuClose}
                  PaperProps={{
                    sx: {
                      border: '1px solid rgba(0,0,0,.15)',
                      boxShadow: '3px 3px 3px 0 #e4ebf4',
                    },
                  }}
                >
                  <MenuItem
                    sx={{ fontSize: '13px', color: '#8f9ca7' }}
                    onClick={handleMenuClose}
                  >
                    Copy to all services
                  </MenuItem>
                  <MenuItem
                    sx={{ fontSize: '13px', color: '#8f9ca7' }}
                    onClick={handleMenuClose}
                  >
                    Copy to the same category services
                  </MenuItem>
                </Menu>
              </Grid>
            </Grow>
          </Grid>
        ))
      ) : (
        <>
          <Grid xs={12} sm={12} sx={{ py: { sm: 4 } }}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '1rem',
                }}
              >
                <ProfileImageField image={image} setImage={setImage} />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 }, marginTop: 0 }}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {t('service:drawer.extras.serviceName')}{' '}
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  size="small"
                  variant="outlined"
                  sx={{
                    width: '100%',
                    fontSize: '14px',
                  }}
                  value={info.serviceName}
                  name="serviceName"
                  onChange={handelChange}
                  error={errors.serviceName}
                />
              </Grid>
            </Grid>
            <Grid xs={12} sm={3} sx={{ paddingRight: { sm: 1 }, marginTop: 0 }}>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {t('service:drawer.extras.minQuantity')}{' '}
                  <StyledTooltip
                    title="Default 0 means there is no minimum requirment."
                    placement="top"
                  >
                    <InfoIcon
                      sx={{
                        color: '#ccd7dd',
                        fontSize: '18px',
                        cursor: 'pointer',
                      }}
                    />
                  </StyledTooltip>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  size="small"
                  variant="outlined"
                  sx={{
                    width: '100%',
                    fontSize: '14px',
                  }}
                  type="number"
                  value={info.minQuantity}
                  name="minQuantity"
                  onChange={handelChange}
                />
              </Grid>
            </Grid>
            <Grid xs={12} sm={3} sx={{ paddingRight: { sm: 1 }, marginTop: 0 }}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {t('service:drawer.extras.maxQuantity')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  size="small"
                  type="number"
                  variant="outlined"
                  value={info.maxQuantity}
                  name="maxQuantity"
                  onChange={handelChange}
                  sx={{
                    width: '100%',
                    fontSize: '14px',
                  }}
                />
              </Grid>
            </Grid>

            <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 }, marginTop: 0 }}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {t('service:drawer.extras.price')}{' '}
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  size="small"
                  type="number"
                  variant="outlined"
                  sx={{
                    width: '100%',
                    fontSize: '14px',
                  }}
                  value={info.price}
                  name="price"
                  onChange={handelChange}
                  error={errors.price}
                />
              </Grid>
            </Grid>

            <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 }, marginTop: 0 }}>
              <Grid
                container
                alignItems="center"
                item
                xs={12}
                sx={{
                  cursor: 'pointer',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: '4px',
                  mt: 3.4,
                  mb: 3.6,
                  height: 40,
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingLeft: '.7rem',
                }}
                onClick={() => handelSwitch('hidePrice', !info.hidePrice)}
              >
                <Typography variant="subtitle2">
                  {t('service:drawer.extras.hidePriceInBookingPanel')}
                </Typography>
                <FormControlLabel
                  control={<Switch checked={info.hidePrice} />}
                />
              </Grid>
            </Grid>

            <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 }, marginTop: 0 }}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {t('service:drawer.extras.duration')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  size="small"
                  disablePortal
                  options={duration.map((option) => option.duration)}
                  sx={{
                    width: '100%',
                    fontSize: '14px',
                  }}
                  defaultValue={'0'}
                  onChange={(e, value) => handelSelect('duration', value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={info.duration}
                      name="duration"
                      placeholder="Select..."
                      helperText={' '}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 }, marginTop: 0 }}>
              <Grid
                container
                alignItems="center"
                item
                xs={12}
                sx={{
                  cursor: 'pointer',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: '4px',
                  mt: { xs: 3.4, sm: 2.8 },
                  mb: 3.6,
                  height: 40,
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingLeft: '.7rem',
                }}
                onClick={() => handelSwitch('hideDuration', !info.hideDuration)}
              >
                <Typography variant="subtitle2">
                  {t('service:drawer.extras.hideDurationInBookingPanel')}
                </Typography>
                <FormControlLabel
                  control={<Switch checked={info.hideDuration} />}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      <Box display="flex" marginTop="10px">
        {!isOpenService && (
          <Box
            backgroundColor="#f7f8fb"
            sx={{
              border: '1px solid #e3eaf3',
              fontWeight: 'bold',
              fontSize: '14px',
              textAlign: 'center',
              marginRight: '10px',
              cursor: 'pointer',
            }}
            color="#8f9ca7"
            padding="10px"
            onClick={() => setIsOpenService(true)}
          >
            {t('common:buttons.cancel')}
          </Box>
        )}

        <Box
          backgroundColor="#53d56c"
          sx={{
            border: '1px solid #53d56c',
            fontWeight: 'bold',
            fontSize: '14px',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          color="white"
          padding="10px"
          onClick={isOpenService ? handelNewExtra : handelSave}
        >
          {isOpenService
            ? t('service:drawer.extras.newExtra')
            : t('service:drawer.extras.saveExtra')}
        </Box>
      </Box>
    </Grid>
  );
};

export default Extras;
