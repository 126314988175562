import { Grid, Typography, useMediaQuery } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import classes from './settingsSidebar.module.css';
import TabSettings from './TabSettings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DisableSectionWrapper from '../DisableSectionWrapper';
import { useTranslation } from 'react-i18next';

const SettingsSidebar = ({
  activeTab,
  handleActiveTab,
  openMenu,
  handleOpenMenu,
  mediaQuery1250,
}) => {
  const { t } = useTranslation(['cpSettings']);

  const mediaQuery1020 = useMediaQuery('(min-width:1020px)');

  return (
    <>
      {!openMenu && (
        <Grid
          onClick={handleOpenMenu}
          sx={{
            position: 'fixed',
            top: '105px',
            display: { xs: 'flex', md: 'none' },
            height: '46px',
            width: '46px',
            backgroundColor: '#6C70DC',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0px 2px 2px 0px',
            zIndex: 1,
          }}
        >
          <KeyboardArrowRightIcon
            style={{ fontSize: '26px', color: 'white' }}
          />
        </Grid>
      )}
      {openMenu && (
        <Grid
          onClick={handleOpenMenu}
          sx={{
            position: 'fixed',
            top: '105px',
            right: '0px',
            display: { xs: 'flex', md: 'none' },
            height: '46px',
            width: '46px',
            backgroundColor: '#6C70DC',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0px 2px 2px 0px',
          }}
        >
          <KeyboardArrowLeftIcon style={{ fontSize: '26px', color: 'white' }} />
        </Grid>
      )}
      {/* spacing body out with same grid ratio */}
      <Grid sm={12} md={mediaQuery1250 ? 3.5 : 4.5}></Grid>
      <Grid
        sm={12}
        md={mediaQuery1250 ? 2.7 : 3.5}
        sx={{
          position: 'fixed',
          zIndex: '2',
          display: openMenu ? 'block' : { xs: 'none', md: 'block' },
          boxShadow: '0 0 30px 0 rgb(0 0 0 / 5%)',
          backgroundColor: 'white',
          height: '90vh',
          padding: mediaQuery1020 ? '40px' : '20px',
          overflow: 'scroll',
          ' ::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Typography
          sx={{ fontSize: '28px', display: { xs: 'none', sm: 'block' } }}
        >
          Settings{' '}
          <span
            style={{
              fontSize: '14px',
              backgroundColor: '#ffbb44',
              color: 'white',
              minWidth: '25px',
              height: '25px',
              verticalAlign: 'middle',
              fontWeight: 500,
              textAlign: 'center',
              borderRadius: '10px',
              padding: '5px 9px',
              lineHeight: '18px',
            }}
          >
            4
          </span>
        </Typography>
        <Grid
          className={classes.settingsSidebarContainer}
          sm={12}
          sx={{ marginTop: '20px' }}
        >
          <TabSettings
            tabLabel={t('cpSettings:profileSettings.title')}
            tabName="profile-settings"
            icon={<ManageAccountsIcon sx={{ color: 'white' }} />}
            iconColor="#D1D100"
            description="add necessary information for company to approve your booking"
            handleActiveTab={handleActiveTab}
            activeTab={activeTab}
            subTabs={[
              {
                subTabLabel: t(
                  'cpSettings:profileSettings.profileSettings.title'
                ),
                subTabName: 'profile-settings',
              },
              {
                subTabLabel: 'Change Password',
                subTabName: 'change-password',
              },
              {
                subTabLabel: t(
                  'cpSettings:profileSettings.notifications.title'
                ),
                subTabName: 'notifications',
              },
            ]}
          />

          <DisableSectionWrapper>
            <TabSettings
              tabLabel="Connect"
              tabName="connect-settings"
              icon={<GroupAddIcon sx={{ color: 'white' }} />}
              iconColor="#FC427B"
              description="Perfect for family members or companies that want to connect and have a head administration"
              handleActiveTab={handleActiveTab}
              activeTab={activeTab}
              subTabs={[
                {
                  subTabLabel: 'Invite',
                  subTabName: 'connect-invite',
                },
                {
                  subTabLabel: 'Sub customers',
                  subTabName: 'connect-sub-customer',
                },
              ]}
            />
          </DisableSectionWrapper>

          <TabSettings
            tabLabel="Invoice details"
            tabName="invoice-settings"
            icon={<AccountBalanceWalletIcon sx={{ color: 'white' }} />}
            iconColor="#26C0D6"
            description="add information you want to be viewed in invoice"
            handleActiveTab={handleActiveTab}
            activeTab={activeTab}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SettingsSidebar;
