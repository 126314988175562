import React from 'react';
import back from '../../assets/images/auth_background.png';
// import checkedRadio from "../assets/checked-radio.PNG";
import Footer from '../../components/footer';
// import { FiCheckCircle } from "react-icons/fi";
import CustomtextField from '../../components/CustomTextField/index';
import CustomButton from '../../components/CustomButton';
import CustomProgress from '../../components/ProgressBar';
import UpnizeLogo from '../../assets/images/UpnizeLogo.png';
import {
  Grid,
  FormHelperText,
  Button,
  Container,
  Checkbox,
  Hidden,
  Box,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import PasswordStrengthMeter from '../../components/PasswordStrengthMeter/PasswordStrengthMeter';
import { registerUser } from '../../Api/common/users';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { callSnackbar } from '../../redux/slices/snackbar.slice';
import { useEffect } from 'react';

function Register() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const [state, setState] = useState({
    company: '',
    companyError: '',
    fullname: '',
    fullnameError: '',
    lastname: '',
    lastnameError: '',
    email: '',
    password: '',
    emailError: '',
    passwordError: '',
    isPrivacyChecked: false,
    privacyError: false,
    loading: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.id) {
      navigate('/appointment');
    }
  }, [user]);

  const emailCallBack = (val) => {
    setState((prev) => {
      return { ...prev, email: val };
    });
  };

  const passwordCallBack = (val) => {
    setState((prev) => {
      return { ...prev, password: val };
    });
  };

  const privacyChange = (val) => {
    console.log(val.target.checked);

    setState((prev) => {
      return { ...prev, isPrivacyChecked: val.target.checked };
    });
  };

  const companyNameCallBack = (val) => {
    setState((prev) => {
      return { ...prev, company: val };
    });
  };

  const fullNameCallBack = (val) => {
    setState((prev) => {
      return { ...prev, fullname: val };
    });
  };

  const lastNameCallBack = (val) => {
    setState((prev) => {
      return { ...prev, lastname: val };
    });
  };

  const validateEmail = (emailAdress) => {
    let regexEmail =
      /^[\w+]+([\.-]?[\w+]+)*@[\w+]+([\.-]?[\w+]+)*(\.\w{2,3})+$/;
    if (emailAdress.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  };

  const register = () => {
    setState((prev) => {
      return {
        ...prev,
        emailError: '',
        passwordError: '',
        companyError: '',
        fullnameError: '',
        lastnameError: '',
        privacyError: false,
      };
    });
    var error = 0;
    if (state.company === '') {
      setState((prev) => {
        return { ...prev, companyError: 'Please enter company name' };
      });
      error = 1;
    }
    if (state.fullname === '') {
      setState((prev) => {
        return { ...prev, fullnameError: 'Field Required' };
      });
      error = 1;
    }
    if (state.lastname === '') {
      setState((prev) => {
        return { ...prev, lastnameError: 'Field Required' };
      });
      error = 1;
    }
    if (state.password === '') {
      setState((prev) => {
        return { ...prev, passwordError: 'Please enter password' };
      });
      error = 1;
    }
    if (!validateEmail(state.email)) {
      setState((prev) => {
        return { ...prev, emailError: 'Please enter valid email' };
      });
      error = 1;
    }
    if (!state.isPrivacyChecked) {
      setState((prev) => {
        return { ...prev, privacyError: true };
      });

      error = 1;
    }

    //privacyError
    if (error === 0) {
      setState((prev) => {
        return { ...prev, loading: true };
      });

      // CALL API HERE FOR ADMIN/TENANT
      const data = {
        name: state.company,
        email: state.email,
        password: state.password,
        firstName: state.fullname,
        lastName: state.lastname,
        status: 'active',
        phone: '',
        expiresOn: '2029-12-31',
        dob: '03-03-1990',
        gender: 'Male',
        type: 'Tenant',
        belongsTo: 'Barbaros',
        sendWelcome: 'true',
      };
      registerUser(data).then(
        (res) => {
          console.log(res.data);
          if (res.status === 200) {
            setState((prev) => {
              return { ...prev, loading: false };
            });
            navigate(`/login?confirmRegister=true`);
          }
          return;
        },
        (rej) => {
          setState((prev) => {
            return { ...prev, loading: false };
          });
          dispatch(
            callSnackbar({ message: rej?.response?.data, type: 'error' })
          );
          return;
        }
      );
    }
  };

  var widthPercent = window.innerWidth > 750 ? '60%' : '90%';

  return (
    <>
      {!user?.id && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: '#F9F8FF',
            minHeight: '100vh',
            px: { md: '50px', xs: '10px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              flex: 1,
            }}
          >
            <div
              style={{
                width: { xs: '100%', sm: '100%', md: widthPercent },
                backgroundColor: '#F9F8FF',
                height: '100%',
              }}
            >
              <Hidden lgUp>
                <Grid
                  item
                  xs={12}
                  align="center"
                  sx={{
                    my: 6,
                  }}
                >
                  <img src={UpnizeLogo} alt="Banner" width={'200px'} />
                </Grid>
              </Hidden>
              <div>
                <Box component="section" maxWidth="lg" dir="rtl">
                  <Grid
                    lg={12}
                    item
                    container
                    alignItems="stretch"
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Grid
                      item
                      lg={6}
                      dir="ltr"
                      sx={{ backgroundColor: '#ffffff' }}
                    >
                      <div
                        style={{
                          boxShadow: '1px 2px 9px #C0C0C0',
                          padding: '2em',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '14px',
                            fontWeight: '600',
                            display: 'block',
                          }}
                        >
                          Get started with Upnize today
                        </span>
                        <br />
                        <Typography fontWeight="bold" sx={{ fontSize: '2rem' }}>
                          Register your company
                        </Typography>

                        <span style={{ fontSize: '14px' }}>
                          Already have an account?{' '}
                          <b>
                            <a
                              style={{
                                textDecoration: 'none',
                                color: '#1976D2',
                              }}
                              href="/login"
                            >
                              Log in
                            </a>
                          </b>
                        </span>
                        <br />
                        <br />
                        <CustomtextField
                          e2e="company-name"
                          customData={{ label: 'Company Name' }}
                          onChange={(event) => {
                            companyNameCallBack(event.target.value);
                          }}
                          error={state.companyError}
                          helperText={
                            state.companyError
                              ? 'You need to enter a Company name'
                              : ' '
                          }
                        />

                        <span
                          style={{
                            fontSize: '0.8rem',
                            display: 'block',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          Your personal information is needed for you to log in.
                        </span>

                        <Grid sx={{ mt: 1 }}>
                          <CustomtextField
                            e2e="email"
                            customData={{ label: 'Email' }}
                            onChange={(event) => {
                              emailCallBack(event.target.value);
                            }}
                            error={state.emailError}
                            helperText={
                              state.emailError
                                ? 'You need to enter a valid email address'
                                : ' '
                            }
                          />
                        </Grid>

                        <Grid
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: { xs: 'wrap', md: 'nowrap' },
                          }}
                        >
                          <Grid
                            sx={{ mt: 1, marginRight: { xs: '0', md: '20px' } }}
                            md={6}
                            xs={12}
                          >
                            <CustomtextField
                              e2e="first-name"
                              customData={{ label: 'First Name' }}
                              onChange={(event) => {
                                fullNameCallBack(event.target.value);
                              }}
                              error={state.fullnameError}
                              helperText={
                                state.fullnameError
                                  ? 'First Name Required'
                                  : ' '
                              }
                            />
                          </Grid>

                          <Grid sx={{ mt: 1 }} md={6} xs={12}>
                            <CustomtextField
                              e2e="last-name"
                              customData={{ label: 'Last Name' }}
                              onChange={(event) => {
                                lastNameCallBack(event.target.value);
                              }}
                              error={state.lastnameError}
                              helperText={
                                state.lastnameError ? 'Last Name Required' : ' '
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid sx={{ mt: 1 }}>
                          <CustomtextField
                            e2e="password"
                            type="password"
                            customData={{ label: 'Password' }}
                            onChange={(event) => {
                              passwordCallBack(event.target.value);
                            }}
                            error={state.passwordError}
                            helperText={
                              state.passwordError
                                ? 'You need to provide a password'
                                : ' '
                            }
                          />
                          <PasswordStrengthMeter password={state.password} />
                        </Grid>

                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Checkbox
                            e2e="checkbox"
                            onChange={privacyChange}
                            style={{
                              fontSize: 20,
                              color: '#1976D2',
                            }}
                            inputStyle={{ width: '100%' }}
                          />
                          <span style={{ fontSize: '14px' }}>
                            I agree to Upnize.com{' '}
                            <b>
                              <a
                                href="https://upnize.com/privacy-policy"
                                style={{
                                  color: '#1976D2',
                                  textDecoration: 'none',
                                }}
                                target="_blank"
                              >
                                Privacy Policy
                              </a>
                            </b>
                          </span>
                          <FormHelperText
                            style={{
                              color: 'red',
                              display: !state.privacyError ? 'none' : '',
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;Please accept privacy policy
                          </FormHelperText>
                        </Box>
                        {state.loading ? (
                          <CustomProgress />
                        ) : (
                          <CustomButton e2e="sign-up" label="Sign Up" onClick={register} />
                        )}
                      </div>
                    </Grid>
                    <Grid item md={6} sm={10} dir="ltr">
                      <Grid>
                        <Hidden lgDown>
                          <Grid
                            item
                            xs={12}
                            // align="center"
                            style={{ marginLeft: '0.12%' }}
                          >
                            <img
                              src={UpnizeLogo}
                              alt="Banner"
                              width={'200px'}
                            />
                          </Grid>
                        </Hidden>
                      </Grid>
                      <Grid sx={{ marginTop: '50px', display: 'flex' }}>
                        <Box xs={1.5} sx={{ marginRight: '10px' }}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <CheckCircleIcon
                              sx={{ color: '#1976D2', fontSize: '24px' }}
                            />
                          </div>
                        </Box>
                        <Grid xs={10}>
                          <span
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            Booking automation
                          </span>
                          <p style={{ fontSize: '14px', margin: '0' }}>
                            Our Application fully automates online booking,
                            which is the key to automating services business
                          </p>
                        </Grid>
                      </Grid>
                      <Grid sx={{ marginTop: '10px', display: 'flex' }}>
                        <Box xs={1.5} sx={{ marginRight: '10px' }}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <CheckCircleIcon
                              sx={{ color: '#1976D2', fontSize: '24px' }}
                            />
                          </div>
                        </Box>
                        <Grid xs={10}>
                          <span
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            Save time and cost
                          </span>
                          <p style={{ fontSize: '14px', margin: '0' }}>
                            Get rid of repetitive manual tasks!
                            <br />
                            You will minimize the expenses of unnecessary
                            paperwork and focus your resource more efficiently.{' '}
                          </p>
                        </Grid>
                      </Grid>
                      <Grid sx={{ marginTop: '10px', display: 'flex' }}>
                        <Box xs={1.5} sx={{ marginRight: '10px' }}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <CheckCircleIcon
                              sx={{ color: '#1976D2', fontSize: '24px' }}
                            />
                          </div>
                        </Box>
                        <Grid xs={10}>
                          <span
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            Comprehensive reports
                          </span>
                          <p style={{ fontSize: '14px', margin: '0' }}>
                            You never have to worry about the performance of
                            your business anymore. You can receive detail
                            insights into the sales statistics, employee
                            contributions and make comparisons based on the
                            source of revenue
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </div>
          </Box>
          <div dir="ltr">
            <Footer />
          </div>
        </Box>
      )}
    </>
  );
}

export default Register;
