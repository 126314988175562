import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, selectUser } from '../redux/slices/user.slice.js';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { Context } from '../context/Context.js';

function AuthProvider({ children }) {
  const { user, isUserLoading } = useSelector(selectUser);
  const storedUserObj = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //user type context
  const context = useContext(Context);
  // internal state to remove different pages flicking on first render
  const [isUserReady, setIsUserReady] = useState(false);

  useEffect(() => {
    if (!storedUserObj?.id) {
      navigate('/login');
      return;
    }

    // fetch the user
    if (!user?.id && storedUserObj?.id) {
      dispatch(fetchUser());
      return;
    }

    if (!isUserLoading) {
      // making sure user is successfully logged in after loading is completed
      if (!user?.id) {
        navigate('/login');
        return;
      }

      if (user.type === 'tenant' && !context.user.type) {
        context.setUser({ type: 'tenant' });
      }

      if (user.type === 'customer' && !context.user.type) {
        context.setUser({ type: 'customer' });
      }

      // checking if the mandatory welcome page is active
      if (user?.isWelcome) {
        navigate('/welcome');
        return;
      }

      setIsUserReady(true);
    }
  }, [user]);

  return (
    <>
      {isUserReady && !user ? (
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        user?.id && !user?.isWelcome && children
      )}
    </>
  );
}

export default AuthProvider;
