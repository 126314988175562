import {
  Autocomplete,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Setting = ({ setting, setSetting }) => {
  const { t } = useTranslation('service');

  const handelOpenMethod = () => {
    setSetting({ ...setting, isOpenPanel: !setting.isOpenPanel });
  };
  const handelMethod = (data) => {
    setting.method = [...data];
    setSetting({ ...setting });
  };
  return (
    <div>
      <Grid container>
        <Grid xs={12} sm={12} sx={{ py: { sm: 2 } }}>
          <Grid
            container
            alignItems="center"
            item
            xs={12}
            sx={{
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',

              height: 40,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
            }}
            onClick={handelOpenMethod}
          >
            <Typography variant="subtitle2">
              {t('service:drawer.settings.setServiceSpecificPaymentMethods')}:
            </Typography>
            <FormControlLabel
              control={<Switch checked={setting.isOpenPanel} />}
            />
          </Grid>
        </Grid>
        {setting.isOpenPanel && (
          <Grid xs={12} sm={12} sx={{ py: { sm: 1 } }}>
            <Typography marginBottom={1}>
              {t('service:drawer.settings.paymentMethods')}{' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Autocomplete
              multiple
              size="small"
              options={paymentMethod.map((option) => option.title)}
              value={setting.method}
              // getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select..." />
              )}
              sx={{ width: '100%' }}
              onChange={(_event, newData) => handelMethod(newData)}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const paymentMethod = [
  { title: 'Local' },
  { title: 'Giftcard' },
  { title: 'Paypal' },
  { title: 'Cradit Card' },
];
export default Setting;
