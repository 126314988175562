import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useDashboardFilter } from '../../context/DashboardFilterProvider';
import { useEffect } from 'react';
import DashboardTable from '../../components/DashboardTable';
import { DateRangePicker } from 'react-date-range';
import CloseIcon from '@mui/icons-material/Close';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  ClickAwayListener,
  IconButton,
  Popper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import StatusDetailsBar from './StatusDetailsBar';
import LowestCustomerRevenueWidget from './LowestCustomerRevenueWidget';
import YourTasksWidget from './YourTasksWidget';
import InitialGuideWidget from './InitialGuideWidget';
import EasyBookingWidget from './EasyBookingWidget';
import { useTranslation } from 'react-i18next';

const sortData = [
  {
    id: 1,
    key: 'Today',
    name: 'common:DateFilters.today', e2e: 'today-filter'
  },
  {
    id: 2,
    key: 'Yesterday',
    name: 'common:DateFilters.yesterday',e2e: 'yesterday-filter'
  },
  {
    id: 3,
    key: 'Tomorrow',
    name: 'common:DateFilters.tomorrow',e2e: 'tomorrow-filter'
  },
  {
    id: 4,
    key: 'This week',
    name: 'common:DateFilters.thisWeek', e2e: 'this-week-filter'
  },
  {
    id: 5,
    key: 'Last week',
    name: 'common:DateFilters.lastWeek', e2e: 'last-week-filter' 
  },
  {
    id: 6,
    key: 'This month',
    name: 'common:DateFilters.thisMonth', e2e: 'this-month-filter'
  },
  {
    id: 7,
    key: 'This year',
    name: 'common:DateFilters.thisYear',e2e: 'this-year-filter'
  },
  {
    id: 8,
    key: 'Custom',
    name: 'common:DateFilters.custom',e2e: 'custom-filter'
  },

];

const sortList = [
  { id: 1, text: 'common:statuses.pending', key: 'pending' },
  { id: 2, text: 'common:statuses.ongoing', key: 'ongoing' },
  { id: 3, text: 'common:statuses.completed', key: 'completed' },
  { id: 4, text: 'common:statuses.cancelled', key: 'cancelled' },
];

const GridDay = styled(Box)({
  background: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '60px',
  minWidth: '108px',
  margin: '2px',
  cursor: 'pointer',
});

const Dashboard = () => {
  const {
    filterData,
    activeStatusTab,
    setActiveStatusTab,
    getTodayData,
    getYesterdayData,
    getTomorrowData,
    getThisMonthData,
    getThisYearData,
    getThisWeekData,
    getLastWeekData,
    getCustomRangeData,
    setActiveDate,
    activeDate,
  } = useDashboardFilter();
  const [rows, setRows] = useState(null);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [showDate, setShowDate] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('lg'));
  const isXsScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  const { t } = useTranslation([
    'dashboard',
    'common',
    'service',
    'location',
    'customer',
  ]);

  const handleSortByStatus = (id, statusKey) => {
    setActiveStatusTab(statusKey);
  };

  const handelSelect = (value, index) => {
    setActiveDate(index);
    if (value === 'Custom') setShowDate(true);
    else setShowDate(false);
    switch (value) {
      case 'Today':
        getTodayData();
        return;
      case 'Yesterday':
        getYesterdayData();
        return;
      case 'Tomorrow':
        getTomorrowData();
        return;
      case 'This week':
        getThisWeekData();
        return;
      case 'Last week':
        getLastWeekData();
        return;
      case 'This month':
        getThisMonthData();
        return;
      case 'This year':
        getThisYearData();
        return;
      case 'Custom':
        return;
      default:
        getTodayData();
        break;
    }
  };

  const refetchDashboardData = () => {
    handelSelect(sortData[activeDate].name, activeDate);
  };

  // Fetch appointments on selecting custom date range
  useEffect(() => {
    if (selectedDate) {
      console.log(selectedDate);
      getCustomRangeData(selectedDate.startDate, selectedDate.endDate);
    }
  }, [selectedDate]);

  // update table rows on fetch
  useEffect(() => {
    if (filterData) {
      setRows(filterData);
      setIsTableLoading(false);
    }
  }, [filterData]);

  return (
    <Grid
      item
      container
      sx={{
        minWidth: '100%',
        backgroundColor: '#fff',
        background:
          'linear-gradient(180deg, rgba(242,244,247,1) 0%, rgba(255,255,255,1) 19%, rgba(255,255,255,1) 86%, rgba(242,244,247,1) 100%)',
        // paddingLeft: 4,
        // paddingRight: 4,
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          px: '20px !important',
          pb: '40px',
        }}
      >
        <Typography
          sx={{
            marginTop: 2.5,
            // marginBottom: 1,
            fontSize: 24,
            color: '#3A3A50',
          }}
        >
          {t('title')}
        </Typography>
      </Grid>
      <Grid
        item
        spacing={'20px'}
        sx={{
          display: 'felx',
          px: '20px',
        }}
        container
      >
        <EasyBookingWidget refetchDashboardData={refetchDashboardData} />
        <YourTasksWidget
          tasks={filterData?.tasksList}
          refetchDashboardData={refetchDashboardData}
        />
        <LowestCustomerRevenueWidget
          lowestCustomers={filterData?.lowestRevenueCustomerIds}
        />
        <InitialGuideWidget />
      </Grid>
      <Box
        item
        sx={{
          overflowX: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          mt: '40px',
          mb: '20px',
          display: 'flex',
          width: '100%',
          px: '20px',
        }}
        container
      >
        {sortData.map((data, index) => (
          <GridDay
            item
            key={data.id}
            onClick={() => handelSelect(data.key, index)}
            e2e={data.e2e}

            style={
              activeDate === index
                ? {
                    background: '#1976d2',
                    color: '#fff',
                    height: '40px',
                    m: '0px !important',
                  }
                : {
                    color: '#1976d2',
                    backgroundColor: '#fff',
                    height: '40px',
                    m: '0px !important',
                  }
            }
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                }}
                variant="p"
              >
                {t(data.name)}
              </Typography>
            </Box>
          </GridDay>
        ))}
        {showDate && (
          <Grid
            sx={{
              flex: '1',
              overflow: 'hidden',
              pl: 0.5,
              background: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', lg: 'justify-between' },
              // height: '60px',
              height: '40px',
              marginTop: '2px',
              marginLeft: '2px',
              minWidth: '150px',
              cursor: 'pointer',
            }}
          >
            <Box
              sx={{
                overflow: 'hidden',
                flex: 1,
                background: 'white',
                display: 'flex',
                alignItems: 'center',
                height: '60px',
                minWidth: '108px',
                marginTop: '2px',
                cursor: 'pointer',
                justifyContent:
                  !selectedDate && isXsScreen ? 'center' : 'start',
              }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget.parentElement);
                setIsDateRangeOpen(!isDateRangeOpen);
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '1rem',
                  color: selectedDate ? '#495057' : '#929DA6',
                }}
              >
                <CalendarMonthIcon />
              </Box>
              <Typography
                sx={{
                  // display: { xs: "none", md: "block" },
                  whiteSpace: 'nowrap',
                  fontSize: '14px',
                  color: selectedDate ? '#495057' : '#929DA6',
                  marginRight: !selectedDate && isXsScreen ? '55px' : '0',
                }}
              >
                {selectedDate?.startDate
                  ? `From (${selectedDate?.startDate})` + ' to '
                  : 'Select a Date'}
                {selectedDate?.endDate && `(${selectedDate?.endDate})`}
              </Typography>
            </Box>
            {selectedDate && (
              <Box
                display={'flex'}
                alignItems={'center'}
                marginRight={isXsScreen ? '5px' : '15px'}
                onClick={() => {
                  setSelectedDate(null);
                  setDateRange({
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection',
                  });
                }}
              >
                <IconButton>
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
          </Grid>
        )}
      </Box>
      {isDateRangeOpen && (
        <ClickAwayListener
          onClickAway={() => {
            setIsDateRangeOpen((prev) => !prev);
            setAnchorEl(null);
          }}
        >
          <Popper
            placement={
              isXsScreen
                ? 'bottom'
                : isSmallScreen
                ? 'left-end'
                : 'bottom-start'
            }
            open={isDateRangeOpen}
            anchorEl={anchorEl}
            sx={{
              border: '1px solid',
              borderColor: 'rgba(0, 0, 0, 0.08)',
              borderRadius: '15px',
              overflow: 'hidden',
            }}
          >
            <DateRangePicker
              ranges={[dateRange]}
              onChange={(value) => {
                setDateRange(value.selection);
                setSelectedDate({
                  startDate: value.selection.startDate.toDateString(),
                  endDate: value.selection.endDate.toDateString(),
                });
              }}
            />
          </Popper>
        </ClickAwayListener>
      )}
      <StatusDetailsBar filterData={filterData} />
      <Box
        width="100%"
        sx={{
          mx: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            borderBottom: '1px solid #e3eaf3',
            marginTop: '40px',
          }}
        >
          {sortList.map((list) => (
            <Typography
              key={list.id}
              fontSize="14px"
              fontWeight="600"
              marginRight={'15px'}
              paddingX="0px"
              e2e={list.key + '-filter'}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '0.8rem',
                color: '#3A3A50',
              }}
              style={
                activeStatusTab === list.key
                  ? { borderBottom: '3px solid #8468FA' }
                  : {}
              }
              onClick={() => handleSortByStatus(list.id, list.key)}
            >
              {t(list.text)}
              <Box
                size="small"
                sx={{
                  background: '#F2BA5A',
                  color: '#fff',
                  width: '22px',
                  height: '20px',
                  textAlign: 'center',
                  marginLeft: '5px',
                  borderRadius: '8px',
                  justifyContent: 'center',
                  display: 'flex',
                  fontSize: '14px',
                }}
              >
                {filterData?.appointmentsData[list.key].count || '0'}
              </Box>
            </Typography>
          ))}
        </Box>
        <Box marginTop="0px">
          <DashboardTable
            refetchDashboardData={refetchDashboardData}
            data={rows?.categorizedAppointments}
            isTableLoading={isTableLoading}
            activeTab={activeStatusTab}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default Dashboard;
