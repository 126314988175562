import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CustomTypography from '../../components/CustomTypography/index';
import industries from '../../assets/lists/industries.json';
import { Box, Checkbox } from '@mui/material';

function Page2({ data, setData, errors }) {

  const setIndstry = (industry) => {
    setData({
      ...data,
      industry: industry,
    });
  };
  return (
    <Grid container sx={{ width: '100%' }}>
      <Grid item mt={3} md={12} sm={12} xs={12}>
        <CustomTypography
          sx={{ color: '#4473cf ', fontSize: '14px', mb: '5px' }}
        >
          Select an Industry
        </CustomTypography>
        <Autocomplete
          e2e="industry"
          fullWidth
          onChange={(event, value) => {
            setIndstry(value.Name);
          }}
          options={industries}
          sx={{
            width: '100%',
            '& fieldset': { borderRadius: 1.5 },
          }}
          size="small"
          getOptionLabel={(option) => option.Name}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              error={errors.industry}
              helperText={errors.industry ? 'Select an Industry' : ''}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-industry',
              }}
            />
          )}
        />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 1,
          }}
        >
          <Checkbox
            e2e="trial-checkbox"
            checked={data.activateTrial}
            onChange={(e) => {
              setData({ ...data, activateTrial: e.target.checked });
            }}
          ></Checkbox>
          <CustomTypography sx={{ color: '#4473cf ', fontSize: '14px' }}>
            Start a 14 days trial. No credit cards are needed
          </CustomTypography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Page2;
