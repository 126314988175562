import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  DataGrid,
  GridActionsCellItem,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid';
import { useState, useEffect, useCallback } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import CustomTableFooter from '../../components/CustomTableFooter';
import Modal from '../../components/Modal';
import UsageHistory from './UsageHistory';
import AvatarComp from '../../components/Avatar';
import {
  deleteCustomernById,
  getAllJournals,
  getCustomerById,
  getCustomerList,
  importCustomers,
} from '../../Api/tenant/customers';
import { useMemo } from 'react';
import { debounce } from 'lodash';
import { Tab, Tabs } from '@mui/material';
import CustomSettingsTitle from '../../components/CustomSettingsTitle/CustomSettingsTitle';
import PropTypes from 'prop-types';
import Overview from './Overview';
import AppointmentPage from './AppointmentPage';
import JournalAttachment from './JournalAttachment';
import { Link, useParams } from 'react-router-dom';
import DisableSectionWrapper from '../../components/DisableSectionWrapper';
import { Avatar } from '@mui/material';
import crown from '../../assets/images/crown1.svg';
import UpsellJournal from '../UpsellJournal';
import { useDispatch } from 'react-redux';
import { crudTypes } from '../../constants/crudTypes';
import { drawerTypes } from '../../constants/drawerTypes';
import { drawersActions } from '../../redux/slices/drawers.slice';

const row = [
  {
    id: 1,
    customerName: {
      avatar: null,
      email: 'AlexenderInci@gmail.com',
      name: 'Alexender Inci',
    },
    phone: '+880125455225',
    lastAppoinment: '07/10/2022',
    gender: 'male',
    dateOfBirth: '07/10/2022',
  },
  {
    id: 2,
    customerName: {
      avatar: null,
      email: 'marwan@gmail.com',
      name: 'Marwan Samih',
    },
    phone: '+123213312',
    lastAppoinment: '10/10/2022',
    gender: 'male',
    dateOfBirth: '10/10/2022',
  },
];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: '40px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CustomerDetails = () => {
  const [dataId, setDataId] = useState(false);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [drawer, setDrawer] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);
  // const [rows, setRows] = useState([]);
  const [search, setSearch] = useState(null);
  let { customerId } = useParams();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [customerJournals, setCustomerJournals] = useState([]);

  const [customerData, setCustomerData] = useState(null);

  const dispatch = useDispatch();

  const fetchJournals = useCallback(async () => {
    try {
      if (!customerId) return;
      const result = await getAllJournals(customerId);

      setCustomerJournals(result.data);
    } catch (err) {
      console.log(err);
    }
  }, [customerId]);

  const fetchCustomerById = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getCustomerById(customerId);
      setCustomerData(res.data.User);
      setLoading(false);
      // console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  }, [customerId]);

  // fetch on render
  useEffect(() => {
    fetchCustomerById();
    fetchJournals();
  }, [fetchCustomerById, fetchJournals]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  const toggleUsageHistoryModal = (e) => {
    e.stopPropagation();
    setDrawer((prevState) =>
      prevState !== 'usage-history' ? 'usage-history' : ''
    );
  };

  // API

  const fetchCustomers = async () => {
    setTableLoading(true);
    getCustomerList(100, 1).then((res) => {
      setCustomers(res.data);
    });
  };

  // fetch on render
  useEffect(() => {
    fetchCustomers();
  }, []);

  // debounced search event
  const debouncedOnChangeHandler = useMemo(
    () =>
      debounce((e) => {
        setSearch(e.target.value);
      }, 300),
    []
  );

  //cleanup for debounce
  useEffect(() => {
    return () => {
      debouncedOnChangeHandler.cancel();
    };
  }, []);

  // fetch on search
  useEffect(() => {
    if (typeof search === 'string') {
      if (search.length > 3) {
        setTableLoading(true);
        getCustomerList(100, 1, search).then((res) => {
          setCustomers(res.data);
        });
      } else {
        fetchCustomers();
      }
    }
  }, [search]);

  // update table rows on fetch
  useEffect(() => {
    if (customers) {
      // setRows(customers);
      setTableLoading(false);
    }
  }, [customers]);

  const deleteCustomer = (id) => {
    deleteCustomernById(id).then((res) => {
      fetchCustomers();
    });
  };

  const handleUpload = async (file, setFile) => {
    console.log(file);
    const res = await importCustomers(file);
    // store.dispatch(
    //   callSnackbar({
    //     message: res.data.message,
    //     type: 'success',
    //   })
    // );
    handleOpenModal();
    setFile(null);
  };

  const columns = [
    {
      field: 'customerName',
      headerName: 'CUSTOMER NAME',
      sortComparator: (v1, v2) =>
        gridStringOrNumberComparator(v1.name, v2.name),
      valueGetter: (params) => params.row.User,
      renderCell: (params) => (
        <Box sx={{ display: 'flex' }}>
          <AvatarComp
            img={params?.value?.image || null}
            username={`${params.value.firstName} ${params.value.lastName}`}
            sx={{ marginRight: '10px' }}
          />
          <Box>
            <Typography fontSize="14px" color="#292d32">
              {params.value.firstName} {params.value.lastName}
            </Typography>
            <Typography color="#95a0a9" fontSize="12px">
              {params.value.email}
            </Typography>
          </Box>
        </Box>
      ),
      minWidth: 280,
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'PHONE',
      minWidth: 160,
      flex: 1,
      valueGetter: (params) => params.row.User.phone,
    },
    {
      field: 'lastAppoinment',
      headerName: 'LAST APPOINMENT',
      valueGetter: (params) =>
        params.row?.appointments?.length === 0
          ? '-'
          : params.row.appointments[params.row?.appointments?.length - 1]
              ?.service?.name,

      minWidth: 160,
      flex: 1,
    },
    {
      field: 'gender',
      headerName: 'GENDER',
      minWidth: 160,
      flex: 1,
      valueGetter: (params) => params.row.User.gender,
    },
    {
      field: 'dateOfBirth',
      headerName: 'DATE OF BIRTH',
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => params.row.User.dob || 'N/A',
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      maxWidth: 100,
      minWidth: 100,
      getActions: (params) => [
        <GridActionsCellItem
          label="Info"
          onClick={() => {
            dispatch(
              drawersActions.openDrawer({
                type: drawerTypes.CUSTOMER,
                title: 'Customer Info',
                crudType: crudTypes.INFO,
                data: params.row,
                refetchData: () => {
                  fetchCustomers();
                },
              })
            );
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            dispatch(
              drawersActions.openDrawer({
                type: drawerTypes.CUSTOMER,
                title: 'Edit Customer',
                crudType: crudTypes.EDIT,
                submitTitle: crudTypes.EDIT,
                data: params.row,
                refetchData: () => {
                  fetchCustomers();
                },
              })
            );
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            setOpenModal({ open: true, type: 'delete-modal', id: params.id });
          }}
          showInMenu
        />,
      ],
    },
  ];

  const journalData = [
    {
      id: 1,
      title: 'title 1',
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      attachments: [
        'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png',
        'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Microsoft_Office_Word_%282019%E2%80%93present%29.svg/2203px-Microsoft_Office_Word_%282019%E2%80%93present%29.svg.png',
        'https://media.istockphoto.com/id/1200677760/photo/portrait-of-handsome-smiling-young-man-with-crossed-arms.jpg?s=612x612&w=0&k=20&c=g_ZmKDpK9VEEzWw4vJ6O577ENGLTOcrvYeiLxi8mVuo=',
      ],
      lastModified: '2022-09-01 | 08:11',
      createdBy: '',
      created: '2022-09-01 | 08:11',
    },
    {
      id: 2,
      title: 'title 1',
      desc: 'mksngrg grk hmgh gkd hgm hgk n',
      attachments: [
        'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png',
        'https://media.istockphoto.com/id/1200677760/photo/portrait-of-handsome-smiling-young-man-with-crossed-arms.jpg?s=612x612&w=0&k=20&c=g_ZmKDpK9VEEzWw4vJ6O577ENGLTOcrvYeiLxi8mVuo=',
      ],
      lastModified: '2022-09-01 | 08:11',
      createdBy: '',
      created: '2022-09-01 | 08:11',
    },
    {
      id: 3,
      title: 'title 1',
      desc: 'mksngrg grk hmgh gkd hgm hgk n',
      attachments: [
        'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png',
      ],
      lastModified: '2022-09-01 | 08:11',
      createdBy: '',
      created: '2022-09-01 | 08:11',
    },
  ];

  function createData(
    id,
    title,
    attachments,
    lastModified,
    createdBy,
    created,
    actions
  ) {
    return {
      id,
      title,
      attachments,
      lastModified,
      createdBy,
      created,
      actions,
    };
  }

  const rows = [
    createData(1, 'Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
    createData(2, 'Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
    createData(3, 'Eclair', 262, 16.0, 24, 6.0, 3.79),
    createData(4, 'Cupcake', 305, 3.7, 67, 4.3, 2.5),
    createData(5, 'Gingerbread', 356, 16.0, 49, 3.9, 1.5),
  ];

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        // paddingLeft: 4,
        // paddingRight: 4,
        px: '20px',
      }}
    >
      {openModal.type === 'delete-modal' ? (
        <Modal
          handleOpenModal={handleOpenModal}
          setDataId={setDataId}
          type={'DELETE'}
          open={openModal.open}
          id={openModal.id}
          handleDelete={deleteCustomer}
        />
      ) : (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'UPLOAD'}
          open={openModal.open}
          handleUpload={handleUpload}
        />
      )}

      {drawer === 'usage-history' && (
        <UsageHistory toggleDrawer={toggleUsageHistoryModal} />
      )}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          // alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        {/* <Typography
          sx={{
            marginTop: 2.5,
            marginBottom: 1,
            fontSize: 24,
            color: '#3A3A50',
          }}
        >
          Customer
        </Typography> */}
        <Box
          sx={{
            width: '100%',
            marginTop: 2.5,
            marginBottom: 1,
          }}
        >
          <CustomSettingsTitle
            title={
              <Link
                style={{ textDecoration: 'none', color: '#3A3A50' }}
                to="/customer"
              >
                Customer
              </Link>
            }
            subTitle={`${customerData?.firstName || '-'} ${
              customerData?.lastName || '-'
            }`}
          />
          <Box
            sx={{
              borderBottom: 1,
              borderColor: '#EFF1FC',
            }}
          >
            <Tabs
              variant="scrollable"
              scrollButtons="none"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{
                  fontWeight: 'bold !important',
                  px: '0px',
                  mr: '16px',
                }}
                label="Overview"
                {...a11yProps(0)}
              />
              <DisableSectionWrapper>
                <Tab
                  sx={{
                    px: '0px',
                    mr: '16px',
                    fontWeight: 'bold !important',
                  }}
                  label="Appointments"
                  {...a11yProps(1)}
                />
              </DisableSectionWrapper>
              <Tab
                sx={{
                  px: '0px',
                  mr: '16px',
                  fontWeight: 'bold !important',
                }}
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Journal & attachment
                    <Avatar
                      sx={{
                        ml: 1,
                        backgroundColor: '#3A3A50',
                        width: 20,
                        height: 20,
                        padding: '6px',
                      }}
                      alt="Avatar"
                      src={crown}
                    />
                  </div>
                }
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Overview customerData={customerData} loading={loading} />
          </CustomTabPanel>
          <DisableSectionWrapper>
            <CustomTabPanel value={value} index={1}>
              <AppointmentPage data={[]} />
            </CustomTabPanel>
          </DisableSectionWrapper>
          <CustomTabPanel value={value} index={2}>
            {/*  <JournalAttachment customerData={customerData} customerJournals={customerJournals} fetchJournals={fetchJournals} /> */}
            <UpsellJournal />
          </CustomTabPanel>
        </Box>
      </Grid>

      {/* --------------------TABLE--------------------------------- */}
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px' }}
      >
        <div style={{ width: '100%' }}>
          <DataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                fontWeight: '900 !important',
              },
              '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                border: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            rows={rows}
            columns={columns}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            loading={tableLoading}
            selectionModel={selectionModel}
            autoHeight
            components={{
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default CustomerDetails;
