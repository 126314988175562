import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const langs = [
  { label: 'ENG', text: 'Write here your company story…', key: 'stories[ENG]' },
  {
    label: 'SV',
    text: 'Berätta här historien om företaget…',
    key: 'stories[SV]',
  },
  {
    label: 'ESP',
    text: 'Cuente aquí la historia de la empresa…',
    key: 'stories[ESP]',
  },
  {
    label: 'DU',
    text: 'Erzählen Sie hier die Geschichte des Unternehmens…',
    key: 'stories[DU]',
  },
];

var modules = {
  toolbar: false,
};

const LocationStory = ({ locationStory, setLocationStory }) => {
  const [currentLang, setCurrentLang] = useState({
    label: 'ENG',
    text: 'Write here your company story…',
    key: 'stories[ENG]',
  });

  const { t } = useTranslation('location');

  const placeholderRef = useRef('');
  useEffect(() => {
    placeholderRef.current.getEditor().root.dataset.placeholder =
      currentLang.text;
    placeholderRef.current.getEditor().root.dataset.placeholder =
      currentLang.text;
  }, [currentLang.label]);

  return (
    <Grid sx={{ width: '100%', pb: 7, minHeight: 'calc(100vh - 250px)' }}>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          paddingRight: '24px',
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: '14px',
            mb: 1,
            alignSelf: { xs: 'flex-start', sm: 'auto' },
          }}
        >
          {t('location:drawer.locationStory.writeSomethingAboutTheLocation')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {langs.map((lang, index) => (
            <Box
              onClick={() => {
                setCurrentLang({ ...lang });
              }}
              sx={{
                display: 'inline-block',
                width: '36px',
                height: '24px',
                padding: '20px 25px',
                borderLeft: '2px solid #E7E3FF',
                borderTop: '2px solid #E7E3FF',
                borderBottom: '2px solid #E7E3FF',
                borderRight:
                  index === langs.length - 1 ? '2px solid #E7E3FF' : '',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                '&:hover': {
                  color: '#fff',
                  backgroundColor: '#D1CDFF',
                },
                backgroundColor:
                  currentLang.label === lang.label ? '#D1CDFF' : '#fff',
                color: currentLang.label === lang.label ? '#fff' : '#000',
                transition: 'all 0.25s',
              }}
            >
              {lang.label}
            </Box>
          ))}
        </Box>
      </Grid>

      <ReactQuill
        value={locationStory[currentLang.key]}
        onChange={(v) => {
          console.log(v);
          setLocationStory((prev) => {
            return { ...prev, [currentLang.key]: v };
          });
        }}
        // value={locationStory[currentLang.key]}
        // onChange={(v) => {
        //   console.log(v);
        //   setLocationStory((prev) => {
        //     return { ...prev, [currentLang.key]: v };
        //   });
        // }}
        theme="snow"
        modules={modules}
        placeholder={currentLang.text}
        ref={placeholderRef}
      ></ReactQuill>
    </Grid>
  );
};

export default LocationStory;
