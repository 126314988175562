import React from 'react';
import { Box, Grid, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import CustomTableFooter from '../../../components/CustomTableFooter';
import classes from './appointment.module.css';
import StatusChip from '../../../components/StatusChip';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import PriceFormatter from '../../../utils/PriceFormatter';
import NotesCard from '../../../components/NotesCard';
import ResponsiveTable from '../../../components/ResponsiveTable';
import Drawer from './Drawer';
import Modal from '../../../components/Modal';
import {
  cancelCustomerAppointmentById,
  getCustomerAppointmentList,
} from '../../../Api/customer/appointments';

import { defaultPageSizeOptions } from '../../../constants';
import NoData from '../../../components/NoData';
import useFilterBar from '../../../hooks/useFilterBar';
import { getQueries } from '../../../utils/GetQueries';
import { useTranslation } from 'react-i18next';

function Index() {
  const [appointments, setAppointments] = useState(null);
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  const [selectedDate, setSelectedDate] = useState(null);
  const [drawerData, setDrawerData] = useState(null);
  const [drawerType, setDrawerType] = useState('DETAILS');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isRateModalOpen, setIsRateModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState({ open: false });
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [commentsAnchorEl, setCommentsAnchorEl] = useState({
    element: null,
    appointment: null,
  });

  const { FilterBar, FilterButton, state } = useFilterBar({
    filters: [
      {
        id: 1,
        title: 'Select date',
        type: 'date',
        queryLabel: 'date',
      },
    ],
  });

  const { t } = useTranslation(['cpAppointment', 'common']);

  // handle comments display
  const handleCommentsClick = (appointment) => (event) => {
    setCommentsAnchorEl({ element: event.currentTarget, appointment });
  };

  const handleCloseComments = () => {
    setCommentsAnchorEl({ element: null, appointment: null });
  };

  const commentsOpen = Boolean(commentsAnchorEl.element);

  const handelClick = (type, id, data) => {
    setDrawerType(type);
    setIsDrawerOpen(true);
    setDrawerData(data);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // API
  const fetchAppointments = () => {
    setIsTableLoading(true);
    getCustomerAppointmentList(pageSize, pageCount).then((res) => {
      setAppointments(res.data);
    });
  };

  // fetch on render
  useEffect(() => {
    fetchAppointments();
  }, []);

  // fetch on search
  useEffect(() => {
    getQueries(state).then((queries) => {
      setIsTableLoading(true);
      getCustomerAppointmentList(pageSize, pageCount, queries).then((res) => {
        setAppointments(res.data);
      });
    });
  }, [state, pageSize, pageCount]);

  // update table rows on fetch
  useEffect(() => {
    if (appointments) {
      setRows(appointments);
      setIsTableLoading(false);
    }
  }, [appointments]);

  const cancelAppointment = (id) => {
    cancelCustomerAppointmentById(id).then((res) => {
      fetchAppointments();
    });
  };

  //  Table Columns
  const header = [
    {
      field: 'name',
      headerName: t('common:tableHeaders.employeeName'),
      width: 250,
      renderCell: (params) => (
        <Grid
          container
          xs={7}
          sx={{ marginY: { lg: 2 }, ml: { lg: 2, md: 2 } }}
        >
          {params.row.staff ? (
            <Box
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {params?.row?.staff?.User?.firstName}{' '}
              {params?.row?.staff?.User?.lastName}
            </Box>
          ) : (
            '-'
          )}
        </Grid>
      ),
      renderHeader: (params) => (
        <Grid
          container
          xs={7}
          sx={{ marginY: 1, ml: 2, fontWeight: 900, fontSize: 12 }}
        >
          {params.colDef.headerName}
        </Grid>
      ),
    },
    { field: 'date', headerName: t('common:tableHeaders.date'), width: 270 },
    {
      field: 'service',
      headerName: t('common:tableHeaders.service'),
      width: 225,
      renderCell: (params) => params.value.name,
    },
    {
      field: 'serviceExtra',
      headerName: t('common:tableHeaders.serviceExtra'),
      width: 225,
      renderCell: (params) => (params?.value ? params?.value : '-'),
    },
    {
      field: 'status',
      headerName: t('common:tableHeaders.status'),
      width: 200,
      renderCell: (params) => (
        <>
          {params?.value === 'Custom' ? (
            <StatusChip
              color={params?.row?.statusColor}
              bgColor={params?.row?.statusBgColor}
              text={params?.row?.statusText}
              rounded={true}
              width={'max-content'}
            />
          ) : (
            <StatusChip
              type={'appointment' + params?.value}
              text={params?.value}
              width={'max-content'}
            />
          )}
        </>
      ),
    },

    {
      field: 'price',
      headerName: t('common:tableHeaders.price'),

      width: 160,
      renderCell: (params) => (
        <div className={classes.price__container}>
          {PriceFormatter.format(params?.row?.service?.price || '-')}
          <svg
            onClick={() => {
              handelClick('PAYMENT', params.id, params);
            }}
            style={{ paddingRight: '10px', cursor: 'pointer' }}
            width="20px"
            height="20px"
            viewBox="0 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Combined Shape</title>
            <desc>Created with Sketch.</desc>
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Payments"
                transform="translate(-1799.000000, -381.000000)"
                fill="#ADBFC7"
                fill-rule="nonzero"
              >
                <path
                  d="M1816,381 C1817.7,381 1819,382.3 1819,384 L1819,398.6 L1815.6,401 L1813.3,399.4 L1811,401 L1808.7,399.4 L1806.4,401 L1803,398.7 L1803,391 L1799,391 L1799,384 C1799,382.3 1800.3,381 1802,381 L1816,381 Z M1801,389 L1803,389 L1803,384 C1803,383.4 1802.6,383 1802,383 C1801.4,383 1801,383.4 1801,384 L1801,389 Z M1817,397.6 L1817,384 C1817,383.4 1816.6,383 1816,383 L1804.8,383 C1804.9,383.3 1805,383.6 1805,384 L1805,397.6 L1806.4,398.6 L1808.7,397 L1811,398.6 L1813.3,397 L1815.6,398.6 L1817,397.6 Z M1807,385 L1815,385 L1815,387 L1807,387 L1807,385 Z M1807,389 L1815,389 L1815,391 L1807,391 L1807,389 Z M1807,393 L1811,393 L1811,395 L1807,395 L1807,393 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </svg>
        </div>
      ),
    },
    {
      field: 'duration',
      headerName: t('common:tableHeaders.duration'),

      width: 150,
      renderCell: (params) => params.row.service.duration || '-',
    },

    {
      field: 'actions',
      headerName: t('common:tableHeaders.actions'),

      type: 'actions',
      align: 'right',
      minWidth: 100,
      maxWidth: 100,
      getActions: (params) => [
        <GridActionsCellItem
          label="View details"
          onClick={() => handelClick('DETAILS', params.id, params?.row)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Review this appointment"
          onClick={() => {
            setIsRateModalOpen(true);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Cancel this booking"
          onClick={() => setIsCancelModalOpen({ open: true, id: params.id })}
          showInMenu
        />,
        params.row.comments && params.row.comments.length > 0 ? (
          <>
            <GridActionsCellItem
              onClick={handleCommentsClick(params.row)}
              icon={
                params.row.unreadMsg ? (
                  <MarkUnreadChatAltIcon />
                ) : (
                  <>
                    {/*<MessageIcon sx={{ color: '#ADBFC7' }} />*/}
                    <MarkUnreadChatAltIcon />
                  </>
                )
              }
            />
            <Popover
              open={commentsOpen}
              anchorEl={commentsAnchorEl.element}
              onClose={handleCloseComments}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
            >
              <NotesCard
                comments={commentsAnchorEl?.appointment?.comments}
                hideHeader={true}
                width={'400px'}
                height={'400px'}
              />
            </Popover>
          </>
        ) : (
          <div></div>
        ),
      ],
    },
  ];

  return (
    <>
      {isCancelModalOpen && (
        <Modal
          handleOpenModal={() => {
            setIsCancelModalOpen((prev) => {
              return { open: !prev.open };
            });
          }}
          type="DELETE"
          open={isCancelModalOpen.open}
          id={isCancelModalOpen.id}
          handleDelete={cancelAppointment}
        />
      )}
      {isRateModalOpen && (
        <Modal
          handleOpenModal={() => {
            setIsRateModalOpen((prev) => !prev);
          }}
          type="RATE"
          open={isRateModalOpen}
        />
      )}
      {isDrawerOpen && (
        <Drawer
          toggleDrawer={toggleDrawer}
          drawerType={drawerType}
          drawerData={drawerData}
          setDrawerType={setDrawerType}
        />
      )}
      <Grid
        item
        container
        sx={{
          minWidth: { xs: '100%' },
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        {/* header */}
        <Box item className={classes.page__header}>
          <Typography sx={{ fontSize: 28 }}>
            {t('cpAppointment:title')}

            <span
              style={{
                fontSize: '14px',
                backgroundColor: '#ffbb44',
                color: 'white',
                minWidth: '25px',
                height: '25px',
                verticalAlign: 'middle',
                fontWeight: 500,
                textAlign: 'center',
                borderRadius: '10px',
                padding: '5px 9px',
                lineHeight: '18px',
                marginLeft: '10px',
              }}
            >
              {rows.length}
            </span>
          </Typography>
          <Grid
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <>{FilterButton}</>
          </Grid>
        </Box>
        {/* search */}
        {FilterBar}
        {/* table */}
        <Box className={classes.container}>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {/* desktop table */}
            <DataGrid
              sx={{
                border: 0,
                '& .MuiDataGrid-virtualScroller': {
                  minHeight: '300px !important',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontSize: '12px',
                  fontWeight: '900 !important',
                  textOverflow: 'clip',
                  whiteSpace: 'break-spaces',
                  lineHeight: 1,
                },
                '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                  backgroundColor: 'white',
                  border: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
              }}
              rows={rows}
              columns={header}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              experimentalFeatures={{ newEditingApi: true }}
              selectionModel={selectionModel}
              loading={isTableLoading}
              autoHeight={true}
              rowCount={1000}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => {
                setPageSize(newPageSize);
              }}
              rowsPerPageOptions={defaultPageSizeOptions}
              page={pageCount - 1}
              onPageChange={(newPage) => {
                setPageCount(newPage + 1);
              }}
              paginationMode="server"
              components={{
                NoRowsOverlay: NoData,
                NoResultsOverlay: NoData,
                Footer: () => (
                  <CustomTableFooter
                    selectionModel={selectionModel}
                    // handleDeleteOpenModal={handleDeleteOpenModal}
                  />
                ),
              }}
            />
          </Box>
          {/* mobile table */}
          <ResponsiveTable rows={rows} columns={header} />
        </Box>
      </Grid>
    </>
  );
}

export default Index;
