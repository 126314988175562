import { useEffect, useLayoutEffect, useState } from 'react';
import {
  Autocomplete,
  Grid,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import ProfileImageField from '../../ProfileImageField';
import SaveSlideBar from '../../SaveSlideBar';
import { editCDetails } from '../../../Api/tenant/settings';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../../redux/slices/user.slice.js';
import CustomSettingsTitle from '../../CustomSettingsTitle/CustomSettingsTitle';
import CustomPhoneInput from '../../CustomPhoneInput';
import countrydata from '../AccountSettings/Countrydata.json';
import { useTranslation } from 'react-i18next';

const CompanyDetails = ({ currentSettings, fetchCurrentSettings }) => {
  const [isSaveBar, setIsSaveBar] = useState(false);
  const [displayCompanyLogo, setDisplayCompanyLogo] = useState(false);
  const [image, setImage] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    website: '',
    phone: '',
    country: '',
    city: '',
  });
  const dispatch = useDispatch();
  const [states, setStates] = useState([]);

  const { t } = useTranslation('settings');

  useEffect(() => {
    const getStates = countrydata.find(
      (country) => country.sortname === formData.country
    )?.states;
    setStates(getStates || []);
  }, [formData.country]);

  const handleFormData = (key, selectedValue) => {
    if (key === 'country') {
      setFormData({
        ...formData,
        city: '',
        [key]: selectedValue,
      });
      setIsSaveBar(true);
      return;
    }
    setIsSaveBar(true);
    setFormData({ ...formData, [key]: selectedValue });
  };

  useLayoutEffect(() => {
    setFormData(currentSettings?.cDetails || {});
    setDisplayCompanyLogo(
      currentSettings?.cDetails?.displayCompanyLogo || false
    );
    setImage(currentSettings?.cDetails?.image || null);
  }, [currentSettings?.cDetails]);

  // click handlers
  const handleSave = () => {
    const userFormData = new FormData();

    for (const el in formData) {
      userFormData.append(el, formData[el]);
    }
    userFormData.append('image', image);
    userFormData.append('displayCompanyLogo', displayCompanyLogo);

    editCDetails(userFormData).then((res) => {
      if (res.status === 200) {
        fetchCurrentSettings();
        dispatch(fetchUser())
          .unwrap()
          .then((res) => {
            setIsSaveBar(false);
          });
      }
    });
  };

  const handleCancel = () => {
    setFormData(currentSettings.cDetails);
    setDisplayCompanyLogo(currentSettings.cDetails.displayCompanyLogo);
    setImage(currentSettings.cDetails.companyImage || null);
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
          pb: 1,
          mb: 5,
        }}
      >
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <CustomSettingsTitle
            title={t('settings:company.title')}
            subTitle={t('settings:company.companyDetails.title')}
          />
        </Grid>

        <Grid container style={{ padding: '15px 25px' }}>
          <Grid xs={12} sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}>
            <ProfileImageField
              image={image}
              setImage={setImage}
              setIsSaveBar={setIsSaveBar}
              title={'Upload logo'}
            />
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                {t('settings:company.companyDetails.CompanyName')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData.name}
                onChange={(e) => handleFormData('name', e.target.value)}
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                {t('settings:company.companyDetails.Address')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData.address}
                onChange={(e) => handleFormData('address', e.target.value)}
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                {t('settings:company.companyDetails.Country')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={countrydata}
                value={
                  countrydata.find(
                    (country) => country.sortname === formData.country
                  ) || null
                }
                getOptionLabel={(option) => option.country_name}
                onChange={(e, v) => {
                  handleFormData('country', v.sortname);
                }}
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Choose Country..."
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                {t('settings:company.companyDetails.City')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={states.map((getstate, index) => getstate.state_name)}
                value={formData.city}
                onSelect={(e) =>
                  handleFormData('city', e.target.value.toString())
                }
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Choose City..."
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                {t('settings:company.companyDetails.PhoneNumber')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomPhoneInput
                value={formData.phone}
                onChange={(e) => handleFormData('phone', e)}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                {t('settings:company.companyDetails.Website')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData.website}
                onChange={(e) => handleFormData('website', e.target.value)}
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>

          {/* <Grid
            container
            xs={mediaQuery1238 ? 6 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsSaveBar(true);
                setDisplayCompanyLogo(!displayCompanyLogo);
              }}
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                transition: "display 5s linear",
                borderRadius: "4px",
                paddingX: 2,
                paddingY: 3,
                height: "40px",
              }}
            >
              <Typography variant="subtitle2">
                Display a company logo on the Booking panel
              </Typography>
              <Switch
                size="small"
                sx={{ marginLeft: 2 }}
                onChange={(e) => {
                  setDisplayCompanyLogo(e.currentTarget.checked);
                }}
                checked={displayCompanyLogo}
              />
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default CompanyDetails;
